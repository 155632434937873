import { Card } from "@mui/material";
// import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fileIcon from "../images/fileIcon.png";
import { parseDate } from "../utils/dummyData";
import { clearProject, loadProject } from "../store/features/appSlice";
import { useNavigate } from "react-router-dom";
import { DialoguesContext, mainContext } from "../services/context";

function SearchPane({ searchParams = "" }) {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.app);
  const [filtered, setFiltered] = useState(projects);
  const { setRecordingData } = useContext(mainContext);

  const { setCurrentTime } = useContext(DialoguesContext);
  const navigate = useNavigate();
  useEffect(() => {
    setFiltered(searchProjects(searchParams));
  }, [dispatch, searchParams]);
  const searchProjects = (query) => {
    // Convert the query to lowercase for case-insensitive search
    const searchQuery = query.toLowerCase();

    // Filter the projects based on the search query
    if (Array.isArray(projects) && projects.length > 0) {
      const filteredProjects = projects.filter((project) => {
        const projectName = project.projectName.toLowerCase();
        return projectName.includes(searchQuery);
      });
      return filteredProjects;
    }
  };
  const handleProjectLoad = (projectName, id) => {
    setRecordingData([]);
    setCurrentTime("");
    dispatch(clearProject());
    // console.log("THe id", id);
    if (id) {
      dispatch(loadProject(id));
    }
    navigate(`/app/${projectName}`);
  };
  return (
    <Card
      sx={{
        display: searchParams ? "block" : "none",
        padding: "16px",
        textAlign: "left",
        position: "absolute",
        top: "40px",
        width: "100%",
        "& .firstCell": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          "& img": {
            width: "30px",
            height: "30px",
          },
        },
        "& .secondCell": {
          display: { xs: "none", md: "block" },
          width: "100px",
          textAlign: "center",
        },

        "& .date": {
          display: { xs: "none", md: "block" },
          fontSize: { xs: "12px", md: "16px" },
        },
        // transform: "translate(0%, 100%)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "16px",
        }}
      >
        <p style={{ fontSize: "11px", margin: "0px", marginBottom: "6px" }}>
          Projects
        </p>
        <p
          className="date"
          style={{ fontSize: "11px", margin: "0px", marginBottom: "6px" }}
        >
          Date Created
        </p>
      </div>
      {filtered?.length === 0
        ? `Nothing found on ${searchParams}`
        : filtered?.map(({ projectName, _id, createdAt }) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexBasis: "60%",
                  gap: "24px",
                  cursor: "pointer",
                  alignItems: "center",
                  // borderRadius: "50px",
                  borderBottom: "1px solid #F0F0F0",
                  // borderTop: "1px solid #F0F0F0",
                  marginBottom: "8px",
                }}
                onClick={() => handleProjectLoad(projectName, _id)}
              >
                <div className="firstCell">
                  {/* <img src={fileIcon} alt="" /> */}
                  <p>{projectName}</p>
                </div>
                <div className="secondCell date">
                  <p>{parseDate(createdAt)}</p>
                </div>
              </div>
            );
          })}
    </Card>
  );
}

export default SearchPane;
