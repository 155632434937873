import { Button } from "@mui/material";
import React from "react";

const basicStyle = {
  backgroundColor: "green",
  width: "fit-content",
  padding: "4px",
  color: "white",
  fontSize: "9px",
  marginLeft: "2px",
  borderRadius: "16px",
  outline: "none",
  border: "none",
  fontWeight: "500",

  //   display: "flex",
  //   alignItems: "center",
};
const proStyle = {
  backgroundColor: "red",
  width: "fit-content",
  padding: "4px",
  color: "white",
  fontSize: "9px",
  marginLeft: "2px",
  borderRadius: "16px",
  outline: "none",
  border: "none",
  fontWeight: "500",

  //   display: "flex",
  //   alignItems: "center",
};
const expertStyle = {
  backgroundColor: "#ffaa00",
  width: "fit-content",
  padding: "4px",
  color: "white",
  fontSize: "9px",
  marginLeft: "2px",
  borderRadius: "16px",
  outline: "none",
  border: "none",
  fontWeight: "500",

  //   display: "flex",
  //   alignItems: "center",
};
function Badge({ membership = "Basic" }) {
  if (membership === "Basic") {
    return <p style={basicStyle}>BASIC</p>;
  } else if (membership === "Pro") {
    return <p style={proStyle}>PRO</p>;
  } else if (membership === "Expert") {
    return <p style={expertStyle}>EXPERT</p>;
  }
}

export default Badge;
