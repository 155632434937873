import React, { useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import Logo from "../../../images/rythmoband.png";
import fileIcon from "../../../images/dashProj.png";
import userGuide from "../../../images/userGuide.png";
import uploadIcon from "../../../images/upload.png";
import defaultPic from "../../../images/Default_pfp.svg.png";
import search from "../../../images/Search.png";
import newBell from "../../../images/newBell.svg";
import bell from "../../../images/bell.svg";
import { userItems } from "../../../utils/nav";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Button, Card, Stack, useMediaQuery } from "@mui/material";
import { eraseCookie, getCookie } from "../../../utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfos, logout } from "../../../store/features/userSlice";
import { useState } from "react";
import SearchPane from "../../../components/SearchPane";
import Notifications from "./Notifications";
import ProfilePanel from "./ProfilePanel";
import axios from "axios";
import SrtUploader from "../../../components/srtuploader/srtuploader";
import {
  clearProject,
  setProjectLoaded,
  updateProjectName,
} from "../../../store/features/appSlice";
import { loadJson } from "../../../services/srtreader";
import { useContext } from "react";
import { DialoguesContext, mainContext } from "../../../services/context";
import SuggestionForm from "../../../components/Suggestion/SuggestionForm";
const drawerWidth = 120;
function AppNav({ online }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [inputMode, setInputMode] = useState(false);
  const [nameState, setNameState] = useState("");
  //   const match = useMatch();
  const dispatch = useDispatch();
  const [showNotif, setShowNotif] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [showUploader, setShowUploader] = useState(false);
  //   const isSmallScreen = useMediaQuery()
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { projectName, saveData } = useSelector((state) => state.app);
  const {
    setRecordingData,
    projectLoaded,
    setprojectLoaded,
    showExport,
    setShowExport,
  } = useContext(mainContext);

  const { setCurrentTime } = useContext(DialoguesContext);
  useEffect(() => {
    // if (online) {
    dispatch(getUserInfos());
    // }
    const loadProject = async () => {
      try {
        let savedArray = await loadJson(saveData);

        const len = savedArray.length;
        // ("issue", savedArray[len - 1].currentTime);
        //New structure
        setCurrentTime(savedArray[len - 1].currentTime);
        // ("THe NEW ISSUE", [...savedArray.slice(0, len - 1)]);
        setRecordingData([...savedArray.slice(0, len - 1)]);
      } catch (error) {
        // (error);
      }
    };
    if (saveData && !projectLoaded) {
      // ("HAAAAAAAA");
      loadProject()
        .then(() => {
          // ("Loaded");
        })
        .catch((error) => {
          // ("error loading project:", error);
        });
      setprojectLoaded(true);
    }

    // window.addEventListener("beforeunload", handleBeforeUnload);

    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
    return () => {
      // window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch, showNotif, showPanel, online, saveData, projectLoaded]);
  const handleOutsideClick = (event) => {
    // ("clicked");
    if (!event.target.closest(".notification-panel") && showNotif) {
      setShowNotif(false);
    }
    if (!event.target.closest(".profile-panel") && showPanel) {
      setShowPanel(false);
    }
    // if (!event.target.closest(".projectName") && inputMode) {
    //   setInputMode(false);
    // }
  };
  const [searchValue, setSearchValue] = useState("");

  const [link, setLink] = useState("");
  const { user } = useSelector((state) => state.user);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const containerStyles = {
    "& .pane, .uploadPane": {
      // display: "none",
      position: "fixed",
      left: "128px",
      right: "0px",
      top: "100px",
      //   bot
      zIndex: "99",
    },
    "& .mobilePane, .mobileUploadPane": {
      // display: "none",
      position: "fixed",
      //   left: "128px",
      left: "8px",
      top: "50%",
      //   bot
      zIndex: "99",
    },
    "& .uploadPane": {
      top: "150px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
      width: "fit-content",
      padding: "24px",
      borderRadius: "8px",
      "& label": {
        // display: "none",
        margin: "0px !important",
        // padding: "0px",
      },
    },
    "& .mobileUploadPane": {
      left: "180px",
      display: "flex",
      flexDirection: "column",
      top: "20vh",
      padding: "20px",
      // bottom: "40px",
    },
  };

  const HeaderStyles = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    gap: "8px",
    ".logo": {
      width: "clamp(150px,8vw, 200px)",
      cursor: "pointer",
      //   margin: "30px",
      //   marginTop: "21px",
      //   marginBottom: "60px",
    },

    "& .notifBell, .notifBellNew": {
      //   display: "none",
      width: "24px",
      height: "28px",
      cursor: "pointer",
    },

    ".notifBell": {
      width: "22px",
      height: "22px",
    },

    ".projectName": {
      // display: "none",
      fontSize: "16px",
      padding: "6px",
    },
  };
  const NavStyles = {
    display: "flex",
    backgroundColor: "#eee",
    // overflow: "hidden",
    minHeight: "100vh",

    ".profile": {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      alignItems: "center",
      "& p": {
        fontSize: "14px",
        color: "black",
        margin: "0px",
        marginTop: "8px",
      },

      "& img": {
        width: "50px",
        height: "50px",
        cursor: "pointer",
        border: "1px solid black",
        borderRadius: "50px",
        padding: "4px",
      },
    },
  };
  const sideDrawer = {
    display: "flex",
    flexDirection: "column",

    overflow: "visible",

    height: "100vh",
    borderRight: "1px solid #707070",
    paddingTop: "90px",
    ".active": {
      backgroundColor: "rgba(25, 85, 170, 1)",
      color: "white",
      transition: ".1s ease-out",
    },
    "& button": {
      //   display: "none",\
      flexGrow: "auto",
    },
    ".option": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      gap: "8px",
      "& img": {
        width: "40px",
      },
      "& p": {
        margin: "0px",
      },
    },
  };
  const mobileBar = {
    display: {
      xs: "flex",
      sm: "none",
    },
    // justifyContent: "space-between",
    alignItems: "center",

    // paddingTop: "18px",
    padding: "14px",
    position: "fixed",
    bottom: "0px",
    backgroundColor: "white",
    boxSizing: "border-box",
    width: "100%",
    height: "70px",
    justifyContent: "space-around",
    ".option": {
      display: "flex",
      flexDirection: "column",
      zIndex: "9999",
      alignItems: "center",
      cursor: "pointer",
      gap: "8px",
      "& img": {
        width: "32px",
      },
      "& p": {
        margin: "0px",

        fontSize: "14px",
      },
    },
    "& .mobileProfile": {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      alignItems: "center",
      gap: "8px",
      "& p": {
        fontSize: "14px",
        color: "black",
        margin: "0px",
        // marginTop: "8px",
      },

      "& img": {
        width: "32px",
        height: "32px",
        cursor: "pointer",
        border: "1px solid black",
        borderRadius: "50px",
        padding: "4px",
      },
    },
    // "& .MuiDrawer-paper": {

    // },
  };
  const location = useLocation();
  const navigate = useNavigate();
  const userDashboard = useMatch("/dashboard/:user");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleShowPanel = () => {
    setShowPanel(!showPanel);
  };
  const handleShowUploader = () => {
    setShowUploader(!showUploader);
  };
  const drawer = (
    <Box sx={sideDrawer}>
      {/* <Toolbar /> */}
      {/* <Divider /> */}
      <List
        sx={{
          flexBasis: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "48px",
        }}
      >
        <div className="profile" onClick={handleShowPanel}>
          {user?.profilePic ? (
            <img
              src={user?.profilePic}
              alt="display pfp"
              onClick={handleShowPanel}
              className="profile-panel"
            />
          ) : (
            <img
              src={defaultPic}
              alt="display pfp"
              onClick={handleShowPanel}
              className="profile-panel"
            />
          )}
          <p className="userName">Profile</p>
        </div>
        <div
          className="option"
          onClick={() => {
            navigate("/dashboard/projects");
          }}
        >
          <img src={fileIcon} alt="projects" />
          <p>Projects</p>
        </div>
        <div className="option uploader" onClick={handleShowUploader}>
          <img src={uploadIcon} alt="upload" />
          {showUploader ? <p>click to close</p> : <p>Upload</p>}
        </div>
      </List>
      <div
        className="option"
        style={{ marginBottom: "32px", marginTop: "32px" }}
        onClick={() => {
          navigate("/user-guide");
        }}
      >
        <img src={userGuide} alt="projects" />
        <p>User Guide</p>
      </div>
      <div
        className="option"
        style={{ marginBottom: "16px" }}
        onClick={() => {
          // navigate("/user-guide");
          setShowSuggestion(true);
        }}
      >
        {/* <img src={userGuide} alt="projects" /> */}
        <SupportAgentIcon
          sx={{
            color: "white",
            backgroundColor: "#558DDC",
            fontSize: "42px",
            padding: "4px",
            borderRadius: "24px",
          }}
        />
        <p>Support</p>
      </div>
    </Box>
  );

  const container = document.body;

  const handleNavState = (e, link) => {
    // (e.target);
    // Navigate;
    if (userDashboard && !link) navigate(`${user.userName}`);
    else {
      navigate(link);
    }
    setLink(link);
  };
  const handleShowNotif = async () => {
    setShowNotif(!showNotif);
    try {
      const response = await axios.get(
        "https://fresh-dubs-server.vercel.app/api/user/check-notifs",
        {
          headers: {
            Authorization: "Bearer " + getCookie("jwt_Token"),
          },
          // withCredentials: true,
        }
      );
      // (response.data);
      dispatch(getUserInfos());
    } catch (error) {
      // (error);
    }
  };

  const handleProjectName = (e) => {
    setNameState(e.target.value);
  };

  const toggleInputMode = () => {
    setInputMode(!inputMode);
  };
  return (
    <Box sx={NavStyles}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          borderBottom: "1px solid #707070",
          zIndex: "9999",
        }}
        elevation={0}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "blue" }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box sx={HeaderStyles}>
            <Link to="/" style={{ flexBasis: "auto" }}>
              <img src={Logo} alt="logo" className="logo" />
            </Link>
            <Stack flexDirection="row" alignItems="center" gap="16px">
              {inputMode ? (
                <input
                  className="projectName"
                  type="text"
                  onBlur={(e) => {
                    setInputMode(false);
                    if (nameState) {
                      dispatch(updateProjectName(nameState));
                    }
                    // window.history.pushState({}, null, `/app/${projectName}`);
                  }}
                  autoFocus
                  onFocus={() => {
                    setNameState(projectName);
                  }}
                  value={nameState}
                  onChange={handleProjectName}
                />
              ) : (
                <p
                  style={{ color: "#808080" }}
                  className="projectName"
                  onClick={toggleInputMode}
                >
                  {projectName}
                  {/* {saveData} */}
                </p>
              )}

              <Button
                variant="contained"
                onClick={() => {
                  setShowExport(!showExport);
                }}
              >
                {showExport ? "Close" : "Export"}
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          //   display: "none",
          width: { xs: "100%", sm: drawerWidth, md: drawerWidth },
          //   height: "50px",
          // position: "fixed",
          // bottom: "0px",
          flexShrink: { sm: 0 },
          //   position: isSmallScreen && "fixed",
          zIndex: "99",
          //   right: "0px",
        }}
        aria-label="mailbox folders"
      >
        <Box sx={mobileBar}>
          <div className="mobileProfile" onClick={handleShowPanel}>
            {user?.profilePic ? (
              <img
                src={user?.profilePic}
                alt="display pfp"
                onClick={handleShowPanel}
                className="profile-panel"
              />
            ) : (
              <img
                src={defaultPic}
                alt="display pfp"
                onClick={handleShowPanel}
                className="profile-panel"
              />
            )}
            <p className="userName">Profile</p>
          </div>
          <div
            className="option"
            onClick={() => {
              navigate("/dashboard/projects");
            }}
          >
            <img src={fileIcon} alt="projects" />
            <p>Projects</p>
          </div>
          <div className="option" onClick={handleShowUploader}>
            <img src={uploadIcon} alt="upload" />
            {showUploader ? <p>click to close</p> : <p>Upload</p>}
          </div>

          <div
            className="option uploader"
            onClick={() => {
              navigate("/user-guide");
            }}
          >
            <img src={userGuide} alt="projects" />
            <p>User Guide</p>
          </div>
          <div
            className="option"
            // style={{ marginBottom: "16px" }}
            onClick={() => {
              // navigate("/user-guide");
              setShowSuggestion(true);
            }}
          >
            {/* <img src={userGuide} alt="projects" /> */}
            <SupportAgentIcon
              sx={{
                color: "white",
                backgroundColor: "#558DDC",
                fontSize: "36px",
                padding: "4px",
                borderRadius: "24px",
              }}
            />
            <p>Support</p>
          </div>
        </Box>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: "0px",
          paddingTop: "0px",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar /> */}
        <Box sx={containerStyles}>
          {showPanel && (
            <ProfilePanel className={isSmallScreen ? "mobilePane" : "pane"} />
          )}
          {showUploader && (
            <SrtUploader
              className={isSmallScreen ? "mobileUploadPane" : "uploadPane"}
            />
          )}
          <SuggestionForm
            show={showSuggestion}
            setShowSuggestion={setShowSuggestion}
          />

          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default AppNav;
