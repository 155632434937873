import { Box, IconButton, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

// Images

import play from "../images/app/playbtn.png";
import Line from "../images/app/Line.png";
import zoomIn from "../images/app/zoomin.png";
import zoomOut from "../images/app/zoomout.png";
import seekfront from "../images/app/seekfront.png";
import seekback from "../images/app/seekback.png";
import Trim from "../images/app/trim.png";
import undo from "../images/app/undo.png";
import redo from "../images/app/redo.png";
import Record from "../images/app/record.png";
import { Pause } from "@mui/icons-material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import StopCircleIcon from "@mui/icons-material/StopCircle";
// import { Button } from "antd";
import Rythmoband from "./rythmoband/rythmoband";
// import AudioPlayer from "./AudioPlayer";
import TimeSlider from "./TimeSlider";
import { mainContext } from "../services/context";
import audioBufferToWav from "audiobuffer-to-wav";
import RecordRTC from "recordrtc";
import { MakeJson, ToSrtTime } from "../services/srtreader";
import { saveProject, uploadProject } from "../store/features/appSlice";
import { useDispatch, useSelector } from "react-redux";

import SaveIcon from "@mui/icons-material/Save";
import { Card } from "antd";
import { notify } from "reapop";

const audioContext = new AudioContext();

function Editor({ updateParent, ...props }) {
  const {
    recordingData,
    setRecordingData,
    jsonData,
    setJsonData,
    recordings,
    setRecordings,
    toggleSlider,
    setToggleSlider,
    statusArray,
    setChannels,
    setStatusArray,
    redoArray,
    setRedoArray,
    channels,

    showExport,
    setShowExport,
  } = useContext(mainContext);
  const dispatch = useDispatch();
  const [segments, setSegments] = useState([]);
  const [exportStatus, setExportStatus] = useState("null");
  const [link, setLink] = useState("");
  const [wav, setWav] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState("");
  const [receivedVoices, setReceivedVoices] = useState([]);
  const [recordingEndTime, setRecordingEndTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gate, setGate] = useState(false);
  const [zoomPercent, setzoomPercent] = useState(
    ((window.outerWidth - 10) / window.innerWidth) * 100
  );
  const [recorder, setRecorder] = useState({}); //recorder obj
  var remainingStatus = [];
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    // recordingData.map((data, key) => {

    setSegments(recordingData);
    // });
  }, [recordingData]);
  useEffect(() => {
    const instantiateRecorder = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        //MONO Stereo feat
        audio: { channelCount: channels },
        video: false,
      });
      const recorde = new RecordRTC(stream, {
        type: "audio",
        // mimeType: "audio/webm;codecs=pcm",

        numberOfAudioChannels: channels,
      });

      setRecorder(recorde);
    };
    instantiateRecorder();
  }, [channels]);
  useEffect(() => {
    setReceivedVoices([...props.voiceList]);
    // setRecordingData([...props.voiceList]);
  }, [props.voiceList]);
  useEffect(() => {
    //if trim false
    if (gate) {
      handleTrim();
    }
  }, [props.vidHot.trim]);
  useEffect(() => {
    if (props.vidHot.record) {
      record();
      setIsRecording(true);
    } else if (isRecording && props.vidHot.record === false) {
      setIsRecording(false);
      record();
    } else {
      setIsRecording(false);
    }
  }, [props.vidHot.record]);

  //styles
  const editorPane = {
    backgroundColor: "white",
    // padding: "16px",
    borderTop: "1px solid #707070",
    maxWidth: "100vw",
    overflowY: "hidden",
    paddingTop: "16px",

    minWidth: { xs: "100vw", sm: "auto" },

    //   width: { sm: `calc(100% - ${drawerWidth}px)` }
    "& .toolContainer": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingInline: { xs: "24px", md: "16px" },

      "& img": {
        width: { xs: "18px", md: "100%" },
        padding: "0px",
      },
      "& svg": {
        // width: "clamp(20px, 3.5vw, 40px)",
        fontSize: { xs: "28px", md: "32px" },
      },
      "& label": {
        padding: "0px !important",
      },
    },
    width: { xs: "100%" },
    "& .tools": {
      display: "flex",
      gap: { xs: "8px", md: "32px" },
      alignItems: "center",
    },
    "& .left": {
      flexGrow: "1",
      justifyContent: { xs: "space-between", md: "start" },
      // gap: "4px",
    },
    "& .middle": {
      flexGrow: { xs: "1", md: "2" },
      justifyContent: "center",
    },
    "& .right": {
      flexGrow: "1",
      justifyContent: { xs: "center", md: "end" },
      // padding
      ".vol": {
        display: { xs: "none", md: "flex" },
      },
      ".zoom": {
        gap: "8px",
      },
    },
  };

  //Save Feat
  const save = () => {
    if (props.plyr) {
      const { player } = props.plyr.getState();
      let savedRecords = [];
      for (let x of recordingData) {
        // x.
        // console.log(x);
        const save = Object.assign(
          {},
          {
            sourceAudio: x.sourceAudio,
            startTime: x.startTime,
            endTime: x.endTime,
          }
        );
        savedRecords.push(save);
      }
      const dict = [
        ...recordingData,
        {
          currentTime: ToSrtTime(player.currentTime),

          // number: dial_number,
          // position: rythmo_position,
        },
      ];
      // console.log(dict, player.currentTime);

      const data =
        "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dict));

      // return data;
      dispatch(saveProject(data));
      // .then(
      dispatch(notify({ message: "Project Saved" }));
      // );

      dispatch(uploadProject(data));
      // .then(dispatch(notify({ message: "Project Saved" })))
      // .catch((error) => {
      //   store.dispatch(notify({ message: error.response.data }));
      //   console.log(error);
      // });
      // console.log("THE LENGTH", recordingData.length);
      // MakeJson(
      //   //NEW STRUCTURE

      //   ToSrtTime(player.currentTime),
      //   recordingData

      //   //OLD STRUCTURE
      //   // this.props.dialogueNumber,
      //   // this.props.rythmoPosition,
      // );
    }
  };

  //Redo & Undo Logic
  const handleUndo = () => {
    if (recordingData.length > 0 && statusArray.length > 0) {
      const statusObj = statusArray[statusArray.length - 1];
      if (statusObj.status === "created") {
        // console.log("Last status is created", statusObj);
        setRedoArray([...redoArray, statusObj]);
        remainingStatus = statusArray.slice(0, statusArray.length - 1);
        setStatusArray([...remainingStatus]);
        // Now we will remove audio from original array

        const remainingArray = recordingData.filter(
          (_) => _.audioNumber != statusObj.originalObj.audioNumber
        );
        setRecordingData([...remainingArray]);
      }
      if (statusObj.status === "trimmed") {
        // console.log("Last status is trimmed", statusObj);
        setRedoArray([...redoArray, statusArray[statusArray.length - 1]]);
        remainingStatus = statusArray.slice(0, statusArray.length - 1);
        setStatusArray([...remainingStatus]);
        //checks for the trimmed array and plops it off the main recording data
        const remainingArray = recordingData.filter(
          (_) =>
            !statusObj.trimmedArray
              .map((obj) => obj.audioNumber)
              .includes(_.audioNumber)
        );
        setRecordingData([...remainingArray, { ...statusObj.originalObj }]);
      }
      if (statusObj.status === "dragged") {
        // console.log("Last Status is Dragged", statusObj);
        const tempArray = recordingData.filter(
          (_) => _.audioNumber == statusObj.originalObj.audioNumber
        );
        // console.log("TempArray....", tempArray);
        setRedoArray([
          ...redoArray,
          { status: "dragged", originalObj: tempArray[0] },
        ]);
        remainingStatus = statusArray.slice(0, statusArray.length - 1);
        // console.log("Status Array1234", remainingStatus);
        setStatusArray([...remainingStatus]);
        const remainingArray = recordingData.filter(
          (_) => _.audioNumber != statusObj.originalObj.audioNumber
        );
        // console.log("remaining Array...", remainingArray);
        setRecordingData([...remainingArray, { ...statusObj.originalObj }]);
      }
    }
  };
  const handleRedo = () => {
    if (redoArray.length > 0) {
      const redoObj = redoArray[redoArray.length - 1];
      // console.log("RedoObj...", redoObj);
      if (redoObj.status === "created") {
        // console.log("I am created");
        const redoRemainingArray = redoArray.slice(0, redoArray.length - 1);
        setRedoArray([...redoRemainingArray]);
        setRecordingData([...recordingData, redoObj.originalObj]);
        setStatusArray([...statusArray, redoObj]);
      }
      if (redoObj.status === "trimmed") {
        const redoRemainingArray = redoArray.slice(0, redoArray.length - 1);
        setRedoArray([...redoRemainingArray]);
        const remainingArray = recordingData.filter(
          (_) => _.audioNumber !== redoObj.originalObj.audioNumber
        );
        setRecordingData([...remainingArray, ...redoObj.trimmedArray]);
        setStatusArray([...statusArray, redoObj]);
      }
      if (redoObj.status === "dragged") {
        // console.log("I am indside Dragged");
        const redoRemainingArray = redoArray.slice(0, redoArray.length - 1);
        setRedoArray([...redoRemainingArray]);
        const tempArray = recordingData.filter(
          (_) => _.audioNumber === redoObj.originalObj.audioNumber
        );
        setStatusArray([
          ...statusArray,
          { status: "dragged", originalObj: tempArray[0] },
        ]);
        const remainingArray = recordingData.filter(
          (_) => _.audioNumber !== redoObj.originalObj.audioNumber
        );
        setRecordingData([...remainingArray, redoObj.originalObj]);
        // setStatusArray([...statusArray, redoObj]);
      }
    }
  };
  //Trimming logic
  function random(number) {
    return Math.floor(Math.random() * number);
  }
  function trimAudio(audioBuffer, start, end) {
    const sampleRate = audioBuffer.sampleRate;
    const length = audioBuffer.length;
    const channels = audioBuffer.numberOfChannels;

    const startSamples = start * sampleRate;
    const endSamples = end * sampleRate;

    if (
      startSamples >= endSamples ||
      startSamples >= length ||
      endSamples <= 0
    ) {
      return null; // Invalid trim range
    }

    const trimStart = Math.max(0, startSamples);
    const trimEnd = Math.min(length, endSamples);

    const duration = trimEnd - trimStart;

    const trimmedContext = new OfflineAudioContext(
      channels,
      duration,
      sampleRate
    );
    const trimmedBuffer = trimmedContext.createBuffer(
      channels,
      duration,
      sampleRate
    );

    for (let channel = 0; channel < channels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      const trimmedChannelData = trimmedBuffer.getChannelData(channel);
      trimmedChannelData.set(channelData.subarray(trimStart, trimEnd));
    }

    const source = trimmedContext.createBufferSource();
    source.buffer = trimmedBuffer;
    source.connect(trimmedContext.destination);
    source.start();

    return trimmedContext.startRendering();
  }
  async function removeFirst_N_SecondsFromAudio(
    seconds,
    arrayBuffer,
    totalSeconds
  ) {
    const originalArrayBuffer = arrayBuffer; // your original ArrayBuffer
    const bytesPerSecond = 4800 * channels; // sample rate * channels * bytes per sample
    const bytesToRemove = seconds * bytesPerSecond;
    //copy array buffer to prevent disconnection
    const copy = originalArrayBuffer.slice(0);
    const dat = await audioContext?.decodeAudioData(copy);
    // console.log("MY", dat);
    // console.log(seconds);
    const newAud = await trimAudio(
      dat,
      seconds.toFixed(1),
      totalSeconds.toFixed(1)
    );

    // console.log("YOUR", newAud);
    // const bytesToRemove = seconds * bytesPerSecond;
    // console.log("Original Array...", originalArrayBuffer);
    // const totalBytes = originalArray;
    const renderedBuffer2 = audioBufferToWav(newAud);
    const audioBlob = new Blob([renderedBuffer2], {
      type: "audio/wav",
    });

    //
    const totalBytes = originalArrayBuffer.byteLength;
    const newArrayBuffer = originalArrayBuffer.slice(bytesToRemove);

    // const newBlob = new Blob([ss], { type: "audio/mpeg" });
    const updated = await blobToBase64(audioBlob);
    return { newArrayBuffer, updated };
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  async function removeLast_N_SecondsFromAudio(seconds, arrayBuffer) {
    const originalArrayBuffer = arrayBuffer; // your original ArrayBuffer
    // console.log("Even newer issue", originalArrayBuffer);
    // const secondsToRemove = seconds; // number of seconds to remove
    const bytesPerSecond = 4800 * channels; // sample rate * channels * bytes per sample
    const copy = originalArrayBuffer.slice(0);
    const dat = await audioContext?.decodeAudioData(copy);
    // console.log("MY", dat);
    const newAud = await trimAudio(dat, 0, seconds.toFixed(1));
    // const arr = new Uint8Array(arrayBuffer);
    // console.log("YOUR", newAud);
    const bytesToRemove = seconds * bytesPerSecond;
    // console.log("Original Array...", originalArrayBuffer);
    const totalBytes = originalArrayBuffer.byteLength;
    const renderedBuffer2 = audioBufferToWav(newAud);
    const audioBlob = new Blob([renderedBuffer2], {
      type: "audio/wav",
    });

    const newArrayBuffer = originalArrayBuffer.slice(
      0,
      totalBytes - bytesToRemove
    );

    // const newBlob = new Blob([newArrayBuffer], { type: "audio/wav" });
    const lastRemoved = await blobToBase64(audioBlob);
    // console.log("lastRemoved", lastRemoved);
    return { newArrayBuffer, lastRemoved };
  }
  // console.log("statusArray....", statusArray);
  async function divideAudio(
    breakPoint,
    totalSeconds,
    arrayBuffer,
    originalObj
  ) {
    const removeSeconds = totalSeconds - breakPoint;
    // console.log("arraybuffer", arrayBuffer);
    const firstPart = await removeLast_N_SecondsFromAudio(
      removeSeconds,
      arrayBuffer
    );
    const secondPart = await removeFirst_N_SecondsFromAudio(
      breakPoint,
      arrayBuffer,
      totalSeconds
    );

    return {
      firstPart,
      secondPart,
    };
  }
  const base64ToArrayBuffer = async (base64) => {
    // const audioDataURL = "data:audio/webm;codecs=opus;base64,..."; // Your audio data URL
    const response = await fetch(base64);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };
  const handleTrim = async () => {
    setGate(true);
    //Select trimmed segment
    const trimSegment = segments.filter(
      (segment) =>
        segment?.from < props.player.currentTime &&
        segment?.to > props.player.currentTime
    );
    // console.log("Trimming", trimSegment[0]);

    //remaining portion
    const breakValue = props.player.currentTime - trimSegment[0]?.from;
    //Divide the audio
    const buff = await base64ToArrayBuffer(trimSegment[0]?.sourceAudio);
    const recordingDuration = trimSegment[0]?.to - trimSegment[0]?.from;
    const dividedAudio = await divideAudio(
      breakValue,
      recordingDuration,
      buff,
      trimSegment[0]?.buffer,
      trimSegment
    );
    // console.log("divide...", dividedAudio);

    const pullTrimSegment = segments.splice(
      segments.findIndex(
        (segment) =>
          segment?.from < props.player.currentTime &&
          segment?.to > props.player.currentTime
      ),
      1
    );
    //Set up randomized color
    const random1 = random(23);

    const color1 =
      "rgb(" + random(255) + "," + random(255) + "," + random(255) + ")";
    const color2 =
      "rgb(" + random(255) + "," + random(255) + "," + random(255) + ")";
    const base64 = dividedAudio.firstPart.lastRemoved;
    // status for undo/redo
    setStatusArray([
      ...statusArray,
      {
        status: "trimmed",
        originalObj: trimSegment[0],
        trimmedArray: [
          {
            color: color1,
            width:
              (props.player.currentTime / props.player.duration) * 100 -
              (trimSegment[0].from / props.player.duration) * 100,
            audioNumber: random1,
            startTime: trimSegment[0].from,
            endTime: trimSegment[0].from + breakValue,
            widthFrom: (trimSegment[0].from / props.player.duration) * 100,
            widthTo: (props.player.currentTime / props.player.duration) * 100,
            durtion: props.player.duration,
            from: trimSegment[0].from,
            to: props.player.currentTime,
            buffer: dividedAudio.firstPart.newArrayBuffer,
            sourceAudio: dividedAudio.firstPart.lastRemoved,
            base64: dividedAudio.firstPart.lastRemoved,
          },
          {
            color: color2,
            width:
              (trimSegment[0].to / props.player.duration) * 100 -
              (props.player.currentTime / props.player.duration) * 100,
            audioNumber: random1 + 1,
            startTime: trimSegment[0].from + breakValue,
            endTime: trimSegment[0].from,
            widthFrom: (props.player.currentTime / props.player.duration) * 100,
            widthTo: (trimSegment[0].to / props.player.duration) * 100,
            durtion: props.player.duration,
            from: props.player.currentTime,
            to: trimSegment[0].to,
            buffer: dividedAudio.secondPart.newArrayBuffer,
            sourceAudio: dividedAudio.secondPart.updated,
            base64: dividedAudio.secondPart.updated,
          },
        ],
      },
    ]);
    // alter recorded data state
    setRecordingData([
      ...recordingData,
      {
        color: color1,
        width:
          (props.player.currentTime / props.player.duration) * 100 -
          (trimSegment[0].from / props.player.duration) * 100,
        audioNumber: random1,
        startTime: trimSegment[0].from,
        endTime: trimSegment[0].from + breakValue,
        widthFrom: (trimSegment[0].from / props.player.duration) * 100,
        widthTo: (props.player.currentTime / props.player.duration) * 100,
        durtion: props.player.duration,
        from: trimSegment[0].from,
        to: props.player.currentTime,
        buffer: dividedAudio.firstPart.newArrayBuffer,
        sourceAudio: dividedAudio.firstPart.lastRemoved,
        //fields for faulty Fix of blank trim
        originalObj: trimSegment[0],
        trimmed: true,
        firstPart: true,
        base64: dividedAudio.firstPart.lastRemoved,
      },
      {
        color: color2,
        width:
          (trimSegment[0].to / props.player.duration) * 100 -
          (props.player.currentTime / props.player.duration) * 100,
        audioNumber: random1 + 1,
        startTime: trimSegment[0].from + breakValue,
        endTime: trimSegment[0].from,
        widthFrom: (props.player.currentTime / props.player.duration) * 100,
        widthTo: (trimSegment[0].to / props.player.duration) * 100,
        durtion: props.player.duration,
        from: props.player.currentTime,
        //stop
        to: trimSegment[0].to,
        buffer: dividedAudio.secondPart.newArrayBuffer,
        sourceAudio: dividedAudio.secondPart.updated,
        //fields for faulty Fix of blank trim
        originalObj: trimSegment[0],
        trimmed: true,
        secondPart: true,
        base64: dividedAudio.secondPart.updated,
        // sourceAudio: dividedAudio.firstPart.lastRemoved,
      },
    ]);
  };

  //RECORD FUNC
  const record = async () => {
    setIsLoading(true);
    if (isRecording) {
      let endTime = props.getCurrentTime();
      setRecordingEndTime(endTime);

      setIsLoading(false);
      setIsRecording(false);
      //OLD recorder
      // const blob = await recorder.stopRecording();
      // blob.arrayBuffer().then(async (arrayBuffer) => {
      //   const buffer = Buffer(arrayBuffer);
      //   bufferRef.current = buffer;
      //   // await removeLast_N_SecondsFromAudio(5, buffer);
      //   await removeFirst_N_SecondsFromAudio(5, buffer);
      // });

      // const base64 = await blobToBase64(blob);
      const time = recordingStartTime;
      //NEW recorder
      await recorder.stopRecording(async () => {
        const gg = recorder.getBlob();

        // console.log(recorder.mimeType);
        const base64 = await blobToBase64(gg);

        // console.log("The BUFFER", recorder.buffer);
        const buff = await gg.arrayBuffer();
        const buffer = Buffer(buff);
        setRecordingData([
          ...recordingData,
          {
            sourceAudio: base64,
            color:
              "rgb(" +
              random(255) +
              "," +
              random(255) +
              "," +
              random(255) +
              ")",
            width:
              (endTime / props.player.duration) * 100 -
              (recordingStartTime / props.player.duration) * 100,
            widthFrom: (recordingStartTime / props.player.duration) * 100,
            widthTo: (endTime / props.player.duration) * 100,
            durtion: props.player.duration,
            from: recordingStartTime,
            to: endTime,
            recordingDuration: endTime - time,
            audioNumber: jsonData.length + 1 + time,
            buffer: buffer,
            // bufferAddress: bufferRef.current[0],
            url: base64,
            base64,
            startTime: recordingStartTime,
            endTime: endTime,
          },
        ]);
        setStatusArray([
          ...statusArray,
          {
            status: "created",
            originalObj: {
              sourceAudio: base64,
              color:
                "rgb(" +
                random(255) +
                "," +
                random(255) +
                "," +
                random(255) +
                ")",
              width:
                (endTime / props.player.duration) * 100 -
                (recordingStartTime / props.player.duration) * 100,
              widthFrom: (recordingStartTime / props.player.duration) * 100,
              widthTo: (endTime / props.player.duration) * 100,
              durtion: props.player.duration,
              from: recordingStartTime,
              to: endTime,
              recordingDuration: endTime - recordingStartTime,
              audioNumber: jsonData.length + 1 + time,
              buffer: buffer,
              // blob: blobRef.current,
              // bufferAddress: bufferRef.current[0],
              url: base64,
              base64,
              startTime: recordingStartTime,
              endTime: endTime,
            },
          },
        ]);
        props.getJsonData([
          ...jsonData,
          {
            audioNumber: jsonData.length + 1,
            startTime: recordingStartTime,
            endTime: endTime,
            base64,
            buffer: buffer,
          },
        ]);
      });

      setRecordingStartTime("");
      setRecordingEndTime("");
    } else {
      try {
        setRecordingStartTime(props.getCurrentTime());
        //new recorder
        recorder.startRecording();
        //old recorder
        // await recorder.initAudio();
        // await recorder.initWorker();
        // recorder.startRecording();
        setIsLoading(false);
        setIsRecording(true);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        setIsRecording(false);
      }
    }
  };
  const handleExport = async () => {
    if (exportStatus === "exported") {
      const downloadLink = document.createElement("a");
      // aud.current.src = URL.createObjectURL(link);

      // aud.current.play();
      // if (wav === "true") {
      downloadLink.href = URL.createObjectURL(link);
      // } else {
      //   wavToMp3();
      // }

      // setLink(URL.createObjectURL(audioBlob));
      // console.log("DOWNLOAD LINK", downloadLink.href);

      downloadLink.download = wav === "true" ? "audio.wav" : "audio.mp3";
      // console.log("UEEE", `${wav === "true" ? "audio/wav" : "audio/mp3"}`, wav);
      document.body.appendChild(downloadLink);

      downloadLink.click();
      setExportStatus("null");
    } else if (exportStatus === "loading") {
      return;
    } else {
      setExportStatus("loading");
      try {
        //Creation of context

        const audioContext = new AudioContext();
        const len = recordingData.length;
        const offlineContext = new OfflineAudioContext(
          2,
          44100 * recordingData[len - 1].endTime,
          44100
        );

        //loop
        const gainNode = offlineContext.createGain();
        gainNode.gain.value = 1;

        gainNode.connect(offlineContext.destination);
        for (let i = 0; i < recordingData.length; i++) {
          const gg = await fetch(recordingData[i].sourceAudio);
          const response = await gg.arrayBuffer();
          const decoded = await audioContext?.decodeAudioData(response);
          const source = offlineContext.createBufferSource();
          source.buffer = decoded;
          source.connect(gainNode);
          source.start(recordingData[i].startTime);
        }

        const renderedBuffer = await offlineContext.startRendering();
        // console.log("renderedBuffer", renderedBuffer);
        const renderedBuffer2 = audioBufferToWav(renderedBuffer);
        const audioBlob = new Blob([renderedBuffer2], {
          type: `${wav === "true" ? "audio/mp3" : "audio/wav"}`,
        });

        setLink(audioBlob);
        setExportStatus("exported");
      } catch (err) {
        setExportStatus("null");
        console.error(err);
      }
    }
  };
  const handleFileType = (event) => {
    // console.log(event.target.value);
    setWav(event.target.value);
  };

  const handleRecordingType = (event) => {
    // console.log(event.target.value);
    setChannels(event.target.value);
  };
  return (
    <>
      <Box sx={editorPane}>
        {/* <Button
        onClick={this.load}
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px" }}
      >
        load
      </Button> */}
        <Box className="toolContainer">
          <Box className="tools left">
            <IconButton
              variant="contained"
              component="label"
              color="primary"
              // style={{ marginLeft: "10px", marginTop: "10px" }}
              onClick={handleTrim}
              disabled={props.generating}
            >
              <img src={Trim} alt="" />
            </IconButton>
            <IconButton
              disabled={isLoading || props.generating}
              onClick={record}
              variant="contained"
              component="label"
              color="primary"
              // style={{ marginLeft: "10px" }}
              sx={{ fontSize: "40px", color: "#1955AA" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
              disableFocusRipple={true}
              disableElevation={true}
              disableRipple={true}
              blurOnSelect
              // disabled={generating}
            >
              {isRecording ? <StopCircleIcon /> : <img src={Record} alt="" />}
            </IconButton>
            <IconButton
              variant="contained"
              component="label"
              color="primary"
              // style={{ marginLeft: "10px", marginTop: "10px" }}
              onClick={() => handleUndo()}
              disabled={props.generating}
            >
              <img src={undo} alt="" />
            </IconButton>
            <IconButton
              variant="contained"
              component="label"
              color="primary"
              // style={{ marginLeft: "10px", marginTop: "10px" }}
              onClick={() => handleRedo()}
              disabled={props.generating}
            >
              <img src={redo} alt="" />
            </IconButton>
            <IconButton
              onClick={save}
              variant="contained"
              component="label"
              color="primary"
              sx={{ fontSize: "48px", color: "#1955AA" }}
              // style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
              disabled={!props.plyr?.props.src}
            >
              <SaveIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          </Box>
          <Box className="tools middle">
            <IconButton
              onClick={props.changeCurrentTime(-10)}
              variant="contained"
              component="label"
              color="primary"
              // style={{ marginLeft: "10px" }}
            >
              <img src={seekback} alt="" />
            </IconButton>
            {/* {this.state.paused && "fugg"} */}
            {props.paused && !props.vidHot.play ? (
              <IconButton
                onClick={props.play}
                sx={
                  {
                    // display: this.state.paused ? "none" : "inline",
                  }
                }
                variant="contained"
                component="label"
                color="primary"
                // style={{ marginLeft: "10px" }}
                onKeyUp={(e) => {
                  if (e.code === "Space") {
                    e.preventDefault();
                  }
                }}
              >
                <img src={play} alt="" />
              </IconButton>
            ) : (
              <IconButton
                onClick={props.pause}
                variant="contained"
                component="label"
                color="primary"
                sx={{
                  margin: "0px",
                  padding: "0px",
                  color: "#1955AA",
                }}
                // style={{ marginLeft: "10px" }}
                onKeyUp={(e) => {
                  if (e.code === "Space") {
                    e.preventDefault();
                  }
                }}
              >
                {/* pause */}
                <Pause sx={{ fontSize: "40px" }} />
                {/* <img src={pause} alt="" /> */}
              </IconButton>
            )}
            <IconButton
              onClick={props.changeCurrentTime(10)}
              variant="contained"
              component="label"
              color="primary"
              // style={{ marginLeft: "10px" }}
            >
              <img src={seekfront} alt="" />
            </IconButton>
          </Box>

          {/* <Button
        onClick={this.seek(50)}
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px" }}
      >
        currentTime = 50
      </Button> */}
          <Box className="tools right">
            <div
              className="vol"
              style={{
                // display: "flex",
                // flexDirection: "row",
                alignItems: "center",
                // marginLeft: "85%",
              }}
            >
              <IconButton
                onClick={props.changeVolume(-0.1)}
                variant="contained"
                component="label"
                color="primary"
                style={{ marginLeft: "10px" }}
                sx={{ fontSize: "40px", color: "#1955AA" }}
              >
                {/* volume-=0.1 */}
                <VolumeDownIcon />
              </IconButton>
              <img src={Line} alt="" />
              <IconButton
                onClick={props.changeVolume(0.1)}
                variant="contained"
                component="label"
                color="primary"
                style={{ marginLeft: "10px" }}
                sx={{ fontSize: "40px", color: "#1955AA" }}
              >
                {/* volume+=0.1 */}
                <VolumeUpIcon />
              </IconButton>
            </div>
            <div
              className="zoom"
              style={{
                display: "flex",
                // flexDirection: "row",
                alignItems: "center",
                // marginLeft: "85%",
              }}
            >
              <IconButton
                onClick={() => {
                  document.getElementById("mainContainer").style.zoom = `${
                    zoomPercent + 10
                  }%`;
                  setzoomPercent(zoomPercent + 10);
                }}
                variant="contained"
                component="label"
                color="primary"
                // style={{ marginLeft: "10px", height: "30px" }}
              >
                <img src={zoomIn} alt="zoom in" />
              </IconButton>
              <img src={Line} alt="" />
              <IconButton
                onClick={() => {
                  document.getElementById("mainContainer").style.zoom = `${
                    zoomPercent - 10
                  }%`;
                  setzoomPercent(zoomPercent - 10);
                }}
                variant="contained"
                component="label"
                color="primary"
                // style={{ marginLeft: "10px", height: "30px" }}
              >
                <img src={zoomOut} alt="zoom out" />
              </IconButton>
            </div>

            {/* <Button
          onClick={props.setMuted(true)}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px" }}
        >
          muted=true
        </Button>
        <Button
          onClick={props.setMuted(false)}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px" }}
        >
          muted=false
        </Button> */}
          </Box>
        </Box>
        {/* Export */}
        <Box
          sx={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            padding: "32px",
            display: showExport ? "flex" : "none",
            transition: "2s ease-in-out",
            marginTop: "16px",
            marginBottom: "16px",
            flexDirection: { xs: "column", md: "row" },
            gap: "24px",
            justifyContent: "space-around",
          }}
        >
          <Button
            onClick={async () => {
              await props.recorder.resumeRecording();
              props.vidd.play();

              updateParent({
                generating: true,
                pauseRender: false,
              });
            }}
            variant="contained"
            component="label"
            color="primary"
            style={{ marginLeft: "10px" }}
            onKeyUp={(e) => {
              if (e.code === "Space") {
                e.preventDefault();
              }
            }}
          >
            {
              props.generating ? (
                <>
                  {}
                  {props.pauseRender ? (
                    "Continue Rendering"
                  ) : (
                    <>
                      Video Progress:{" "}
                      <span ref={props.vidProg}>loading...</span>
                    </>
                  )}
                </>
              ) : (
                <>Generate Video</>
              )

              // {!this.state.generating && "Generate Video"}
            }
          </Button>
          <Box>
            <div>
              <Button
                onClick={handleExport}
                variant="contained"
                component="label"
                color="primary"
                style={{ marginLeft: "10px" }}
              >
                {exportStatus === "null" && "Export Audio"}
                {exportStatus === "loading" && "Exporting..."}
                {exportStatus === "exported" && "Download Audio"}
              </Button>
            </div>
            <div onChange={(e) => handleFileType(e)}>
              <span>Export as:</span>
              {/* can be changed to have more file formats, for now true or false represents wav or mp3 respectively */}
              <input type="radio" name="fileType" id="mp3" value={false} />
              <label htmlFor="mp3">MP3</label>
              <input
                type="radio"
                name="fileType"
                id="wav"
                value={true}
                disabled={user?.membership !== "Expert"}
              />
              <label htmlFor="wav">WAV</label>
            </div>
            <div onChange={(e) => handleRecordingType(e)}>
              <span>Recording Options</span>
              {/* can be changed to have more file formats, for now true or false represents wav or mp3 respectively */}
              <input type="radio" name="recordingType" id="mono" value={1} />
              <label htmlFor="mono">Mono</label>
              <input type="radio" name="recordingType" id="stereo" value={2} />
              <label htmlFor="stereo">Stereo</label>
            </div>
          </Box>
        </Box>
        {/* mono stereo switch */}

        {props.generating && (
          <>
            <Button
              onClick={() => {
                updateParent({
                  generating: false,
                });

                props.vidd.pause();
                // console.log("The ID", this.state.vidId);

                // clearInterval(this.state.vidId);
                // this.setState({ vidId: null });
                // this.vidId.current = null;
              }}
              variant="contained"
              component="label"
              color="primary"
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
              disabled={props.pauseRender}
            >
              Stop rendering
            </Button>

            <Button
              onClick={() => {
                props.pause();

                // console.log("The ID", this.state.vidId);
                updateParent({ pauseRender: true });
                // clearInterval(this.state.vidId);
                // this.setState({ vidId: null });
                // this.vidId.current = null;
              }}
              variant="contained"
              component="label"
              color="primary"
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
            >
              Pause rendering
            </Button>
          </>
        )}
        <Box
          sx={{ position: "relative", overflow: "hidden", marginTop: "32px" }}
        >
          {/* <Rythmoband
          player={props.player}
          dialogueNumber={props.dialogueNumber}
          rythmoPosition={props.rythmoPosition}
          timer={props.timer}
        /> */}
          {/* <AudioPlayer
        muteVideo={props.muteVideo}
        getCurrentTime={props.getCurrentTime}
        voiceList={props.voiceList}
        videoCurrentTime={props.videocurrentTime}
        videoMuteState={props.videoMuteState}
        videoPlay={props.play}
        videoPause={props.pause}
        vidHot={props.vidHot}
        player={props.player}
        generating={!props.pauseRender}
        getJsonData={props.getJsonData}
      /> */}
          <TimeSlider
            player={props.player}
            onSeekbarChange={props.onSeekbarChange}
            seeker={props.seeker}
            play={props.play}
            pause={props.pause}
            changePlaybackRateRate={props.changePlaybackRateRate}
            jsonArray={props.jsonArray}
            vidHot={props.vidHot}
            generating={!props.pauseRender}
            dialogueNumber={props.dialogueNumber}
            rythmoPosition={props.rythmoPosition}
            timer={props.timer}
          />
        </Box>
      </Box>
    </>
  );
}

export default Editor;
