import { Card } from "@mui/material";
import { Button } from "@mui/material";
import Badge from "../../../components/Badge";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { eraseCookie, getCookie } from "../../../utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfos, logout } from "../../../store/features/userSlice";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

function ProfilePanel({ className }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onload = async () => {
      const base64 = reader.result;
      // Perform any additional processing or validation with the base64Data
      // console.log("url", base64);
      const formData = { base64, email: user.email };
      // formData.append('image', file);

      try {
        const response = await axios.post(
          "https://fresh-dubs-server.vercel.app/api/user/upload-pic",
          formData
        );
        // console.log("Image uploaded successfully:", response.data);
        dispatch(getUserInfos());
      } catch (error) {
        console.error("Error occurred during image upload:", error);
      }
    };
    reader.readAsDataURL(file);
  }, []);
  const { user } = useSelector((state) => state.user);
  const handleLogout = () => {
    // removeCookie("jwt_Token");
    eraseCookie("jwt_Token");
    dispatch(logout());
    window.location.href = "https://fresh-dubs.com/login";
  };
  const handleCustomer = async () => {
    // https://fresh-dubs-server.vercel.app/
    try {
      const response = await axios.get(
        "https://fresh-dubs-server.vercel.app/api/stripe/manage-billing",
        {
          headers: {
            Authorization: "Bearer " + getCookie("jwt_Token"),
          },
          // withCredentials: true,
        }
      );
      const { session } = response.data;
      window.location.href = session;
    } catch (error) {
      console.log(error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <Card
      className={className}
      sx={{
        position: "absolute",
        width: "200px",
        left: "-43%",
        top: "105%",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        // alignItems: "left",
        textAlign: "left",
        // width: "250px",
        // fontSize: "10px",
        "& a": {
          // display: "none",
          // width: "100%",
          // width: "250px",
          // fontSize: "14px ",

          textDecoration: "none",
          // background: "#b0afaf",
          color: "black",
        },
        "& p": {
          margin: "0px",
          cursor: "pointer",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          // borderBottom: "1px solid #333",
          // justifyContent: "center",
          // textAlign: "left",
        }}
      >
        <p>Plan:</p>
        <Badge membership={user?.membership} />
      </div>
      {location.pathname.startsWith("/dashboard") ? (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <input {...getInputProps()} />
          <p>Upload Profile</p>
        </div>
      ) : (
        <Link to={`/dashboard/${user.userName}`}>Go to dashboard</Link>
      )}
      <p onClick={handleCustomer}>Manage Subscription</p>

      {/* <a >hey</a> */}
      <Button
        variant="contained"
        sx={{
          flexBasis: "auto",
          // marginBottom: "30px",
          // marginInline: "16px",
          borderRadius: "8px",
          backgroundColor: "#c70404",
          "&:hover": {
            //   display: "none",
            backgroundColor: "#e10606",
            color: "white",
          },
        }}
        onClick={() => handleLogout()}
      >
        LOGout
      </Button>
    </Card>
  );
}

export default ProfilePanel;
