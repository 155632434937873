import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import Logo from "../../images/freshdubs.png";
import hamburger from "../../images/hamburger.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { navItems, footerItems } from "../../utils/nav";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfos, logout } from "../../store/features/userSlice";
import { getCookie } from "../../utils/cookies";
import Badge from "../Badge";
import { useLocation } from "react-router-dom";
import defaultPic from "../../images/Default_pfp.svg.png";
function Navbar({ online }) {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("the online", online);
    if (getCookie("jwt_Token") && online) {
      dispatch(getUserInfos());
    }
  }, [isAuthenticated, dispatch]);

  const container = window !== undefined ? () => document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const HeaderStyles = {
    display: "flex",
    // alignItems: "end",

    justifyContent: "space-between",
    // px: "clamp(16px, 5vw, 120px) !important",
    // height: "80px",
    "& .logo": {
      // display: "none",
      width: "clamp(150px,8vw, 197px)",
      cursor: "pointer",
    },
    borderBottom: location.pathname.startsWith("/subplayer")
      ? "none"
      : "0.5px solid #a7a5a5",
  };

  const DrawerStyles = {
    display: "none",
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "left" }}>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "16px" }}
      >
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.text}
            disablePadding
            component={Link}
            to={item.link}
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        {user ? (
          <>
            <ListItem
              key={"dashboard"}
              disablePadding
              component={Link}
              // onClick={handleLogout}
              to={`/dashboard/${user?.userName}`}
            >
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={user?.userName} />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <ListItem key={"login"} disablePadding component={Link} to={"/login"}>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={"Login"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );
  return (
    <>
      <AppBar
        component="nav"
        sx={{
          // backgroundColor: "white",
          backgroundColor: location.pathname.startsWith("/subplayer")
            ? "#333"
            : "white",

          display:
            location.pathname.startsWith("/dashboard") ||
            location.pathname.startsWith("/app")
              ? "none"
              : "block",
        }}
        elevation={0}
      >
        <Toolbar sx={HeaderStyles}>
          <Link to="/">
            <img src={Logo} alt="logo" className="logo" />
          </Link>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <img src={hamburger} alt="icon" />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item.text} sx={{ textTransform: "initial" }}>
                <Link
                  style={{
                    color: location.pathname.startsWith("/subplayer")
                      ? "#fff"
                      : "#707070",
                    textDecoration: "none",
                  }}
                  to={item.link}
                >
                  {item.text}
                </Link>
              </Button>
            ))}
            {user ? (
              <Button
                key={"dashboard"}
                sx={{
                  color: "#707070",
                  textTransform: "initial",
                  "& img": {
                    width: "32px",
                    height: "32px",
                    cursor: "pointer",
                    border: "1px solid black",
                    borderRadius: "50px",
                    padding: "4px",
                    marginLeft: "6px",
                  },
                }}
              >
                <Link
                  style={{
                    color: "#707070",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  // onClick={handleLogout}
                  to={`/dashboard/${user?.userName}`}
                >
                  {/* {user?.userName} */}
                  <Badge membership={user?.membership} />
                  {/* {user.membership} */}
                  {user?.profilePic ? (
                    <img
                      src={user?.profilePic}
                      alt="display pfp"
                      // onClick={handleShowPanel}
                      className="profile-panel"
                    />
                  ) : (
                    <img
                      src={defaultPic}
                      alt="display pfp"
                      // onClick={handleShowPanel}
                      className="profile-panel"
                    />
                  )}
                </Link>
              </Button>
            ) : (
              <Button
                key={"Login"}
                sx={{
                  color: "#707070",
                  textTransform: "initial",
                  // textDecoration: "none",
                }}
              >
                <Link
                  style={{ color: "#707070", textDecoration: "none" }}
                  to={"/login"}
                >
                  {"Login"}
                </Link>
              </Button>
            )}
            {!user && (
              <Button
                variant="contained"
                elevation={0}
                sx={{ borderRadius: "50px", backgroundColor: "#1955AA" }}
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                Get Started
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "300px",
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
            },
            "& .logo": {
              // display: "none",
              alignSelf: "center",
              width: "150px",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

export default Navbar;
