import React from "react";
import Section from "../../components/Section/section";
import { Box, Typography } from "@mui/material";
import Lines from "../../images/lines.png";
import pblob1 from "../../images/pblob1.png";
import pblob2 from "../../images/pblob2.png";
import PricingCard from "./PricingCard";
import { pricingOptions } from "../../utils/pricing";
function Pricing() {
  const contentStyles = {
    postion: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent:"stretch"
    gap: "clamp(50px, 10vw, 150px)",
    "& h1": {
      fontSize: "48px",
      margin: "0px",
    },
    "& .pblob1": {
      position: "absolute",
      top: "20%",
      left: "-8%",
    },
    "& .pblob2": {
      position: "absolute",
      bottom: "20%",
      right: "-10%",
    },
    "& .lines": {
      // position: "absolute",
      width: { xs: "50%", md: "200px" },
      // top: "20%",
      // left: "-8%",
    },
    "& .title": {
      display: "flex",
      flexDirection: "column",
      alignSelf: { xs: "center", md: "start" },
      alignItems: { xs: "center", md: "start" },
      marginTop: "24px",
    },
  };
  return (
    <Section>
      <Box sx={contentStyles}>
        <img src={pblob1} className="pblob1" alt="" />
        <img src={pblob2} className="pblob2" alt="" />
        <Box className="title">
          <Typography variant="h1">Pricing</Typography>
          <img src={Lines} alt="" className="lines" />
        </Box>

        <Box
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "space-between",
            alignSelf: { xs: "center", md: "stretch" },
            // maxWidth: "1200px",
            gap: { xs: "24px", md: "280px" },
            // alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {pricingOptions.map((option) => (
            <PricingCard {...option} />
          ))}
        </Box>
      </Box>
    </Section>
  );
}

export default Pricing;
