import React, { useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import Logo from "../../../images/freshdubs.png";
import defaultPic from "../../../images/Default_pfp.svg.png";
import search from "../../../images/Search.png";
import newBell from "../../../images/newBell.svg";
import bell from "../../../images/bell.svg";
import { userItems } from "../../../utils/nav";
import { Button, Card, Stack } from "@mui/material";
import { eraseCookie, getCookie } from "../../../utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfos, logout } from "../../../store/features/userSlice";
import { useState } from "react";
import SearchPane from "../../../components/SearchPane";
import Notifications from "./Notifications";
import ProfilePanel from "./ProfilePanel";
import axios from "axios";
const drawerWidth = 240;
function DashboardNav({ online }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  //   const match = useMatch();
  const dispatch = useDispatch();
  const [showNotif, setShowNotif] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  useEffect(() => {
    if (online) {
      dispatch(getUserInfos());
    }
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch, showNotif, showPanel]);
  const handleOutsideClick = (event) => {
    // console.log("clicked");
    if (!event.target.closest(".notification-panel") && showNotif) {
      setShowNotif(false);
    }
    if (!event.target.closest(".profile-panel") && showPanel) {
      setShowPanel(false);
    }
  };
  const [searchValue, setSearchValue] = useState("");

  const [link, setLink] = useState("");
  const { user } = useSelector((state) => state.user);
  const containerStyles = {};
  const HeaderStyles = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    gap: "8px",

    "& .notifBell, .notifBellNew": {
      //   display: "none",
      width: "24px",
      height: "28px",
      cursor: "pointer",
    },

    ".notifBell": {
      width: "22px",
      height: "22px",
    },
  };
  const NavStyles = {
    display: "flex",
    backgroundColor: "#eee",

    minHeight: "100vh",
    ".searchBar": {
      width: "50%",
      position: "relative",
      "& img": {
        position: "absolute",
        top: "50%",
        left: "16px",
        width: "20px",
        transform: "translate(0%,-50%)",
      },
    },
    "#search": {
      borderRadius: "54px",
      //   padding: "8px",
      backgroundColor: "rgba(236, 236, 236, 1)",
      outline: "none",
      border: "none",
      width: "100%",
      padding: "10px",
      fontSize: "14px",
      paddingLeft: "48px",
    },

    "#search::-webkit-search-cancel-button": {
      //   display: "none",
      cursor: "pointer",
      width: "50px",
      height: "50px",
      fontSize: "16px",
    },

    ".profile": {
      display: "flex",
      position: "relative",

      gap: "22px",
      borderLeft: "2px solid #ececec",
      paddingLeft: "22px",
      alignItems: "center",
      "& .userName": {
        fontSize: "18px",
        color: "black",
      },
      "& img": {
        width: "40px",
        height: "40px",
        cursor: "pointer",
        border: "1px solid black",
        borderRadius: "50px",
        padding: "4px",
      },
    },
  };
  const sideDrawer = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    ".logo": {
      width: "clamp(150px,8vw, 200px)",
      cursor: "pointer",
      margin: "30px",
      marginTop: "21px",
      marginBottom: "60px",
    },
    ".active": {
      backgroundColor: "rgba(25, 85, 170, 1)",
      color: "white",
      transition: ".1s ease-out",
    },
    "& button": {
      //   display: "none",\
      flexGrow: "auto",
    },
  };
  const location = useLocation();
  const navigate = useNavigate();
  const userDashboard = useMatch("/dashboard/:user");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={sideDrawer}>
      <Link to="/" style={{ flexBasis: "auto" }}>
        <img src={Logo} alt="logo" className="logo" />
      </Link>
      {/* <Toolbar /> */}
      {/* <Divider /> */}
      <List sx={{ flexBasis: "100%" }}>
        {userItems.map((item, index) => (
          <ListItem
            key={item.text}
            className={link === item.link && "active"}
            disablePadding
            onClick={(e) => handleNavState(e, item.link)}
          >
            <ListItemButton>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider /> */}
    </Box>
  );

  const container = document.body;

  const handleNavState = (e, link) => {
    // console.log(e.target);
    // Navigate;
    if (link === "subplayer") {
      window.location.pathname = link;
    }
    if (userDashboard && !link) navigate(`${user?.userName}`);
    else {
      navigate(link);
    }
    setLink(link);
  };
  const handleShowNotif = async () => {
    setShowNotif(!showNotif);
    try {
      const response = await axios.get(
        "https://fresh-dubs-server.vercel.app/api/user/check-notifs",
        {
          headers: {
            Authorization: "Bearer " + getCookie("jwt_Token"),
          },
          // withCredentials: true,
        }
      );
      // console.log(response.data);
      dispatch(getUserInfos());
    } catch (error) {
      console.error(error);
    }
  };
  const handleShowPanel = () => {
    setShowPanel(!showPanel);
  };
  return (
    <Box sx={NavStyles}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          borderLeft: "1px solid #eeecec",
        }}
        elevation={4}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "blue" }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={HeaderStyles}>
            <div className="searchBar">
              <img src={search} alt="searchIcon" />
              <input
                type="search"
                id="search"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <SearchPane searchParams={searchValue} />
            </div>
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={{ xs: "8px", sm: "22px", md: "44px" }}
            >
              <div
                style={{ position: "relative" }}
                onClick={handleShowNotif}
                className="notification-panel"
                // onBlur={() => {
                //   setShowNotif(false);
                // }}
              >
                {user?.notifications?.new ? (
                  <img
                    className="notifBellNew"
                    src={newBell}
                    alt="new message"
                    // onBlur={() => {
                    //   setShowNotif(false);
                    // }}
                  />
                ) : (
                  <img
                    className="notifBell"
                    src={bell}
                    // onClick={handleShowNotif}

                    alt="no new message"
                  />
                )}
                {showNotif ? (
                  <Notifications
                    notifications={user?.notifications.messages.slice(0, 6)}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="profile" onClick={handleShowPanel}>
                <p className="userName">{user?.userName}</p>
                {user?.profilePic ? (
                  <img
                    src={user?.profilePic}
                    alt="display pfp"
                    onClick={handleShowPanel}
                    className="profile-panel"
                  />
                ) : (
                  <img
                    src={defaultPic}
                    alt="display pfp"
                    onClick={handleShowPanel}
                    className="profile-panel"
                  />
                )}
                {showPanel && <ProfilePanel />}
              </div>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: "clamp(24px, 5vw, 48px)",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar /> */}
        <Box sx={containerStyles}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardNav;
