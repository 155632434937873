import React from "react";
import Section from "../components/Section/section";
import { Box, Typography, useMediaQuery } from "@mui/material";
// import { Typography } from "antd";
import Lines from "../images/lines.png";
import guide from "../images/guide.png";
import smallGuide from "../images/smallGuide.png";
import pblob1 from "../images/pblob1.png";
import pblob2 from "../images/pblob2.png";
import pblob3 from "../images/pblob3.png";
function UserGuide() {
  const smallScreen = useMediaQuery("(min-width:600px)");
  const guideSection = {
    display: "flex",
    flexDirection: "column",
    "& .pblob1, .pblob2, .pblob3": {
      position: "absolute",
      zIndex: "-1",
    },

    "& .pblob2": {
      bottom: "0px",
      right: "10px",
    },
    "& .pblob3": {
      top: "40%",
      left: "-10%",
    },
    "& .pblob1": {
      bottom: "50%",
      right: "-50px",
    },
    gap: { xs: "32px", sm: "40px", md: "48px" },
    minHeight: "80vh",
    ".title": {
      alignSelf: { xs: "center", md: "flex-start" },
      width: { xs: "80%", md: "500px" },
      display: "flex",
      paddingTop: "20px",
      flexDirection: "column",
      flexBasis: "auto",
      //   justifyContent: "center",
      alignItems: { xs: "center", md: "start" },

      "& h1": {
        // display: "none",
        fontSize: "clamp(24px, 5vw, 40px)",
        color: "#1955AA",
        fontWeight: "400",
        margin: "0px",
        // paddingLeft: "16px",
      },
      "& img": {
        width: "50%",
      },
    },
    "& iframe": {
      alignSelf: "center",
      width: { xs: "90%", md: "560px" },
    },
  };
  return (
    <Section sx={guideSection}>
      <img src={pblob1} className="pblob1" alt="blob1" />
      <img src={pblob2} className="pblob2" alt="blob2" />
      <img src={pblob3} className="pblob3" alt="blob3" />
      <Box className="title">
        <Typography variant="h1">User Guide</Typography>
        <img src={Lines} alt="" className="lines" />
      </Box>
      <iframe
        width="560"
        height="315"
        // src="https://www.youtube-nocookie.com/embed/uX2cXg0vLrw"
        src="https://www.youtube.com/embed/uX2cXg0vLrw"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            flexDirection: "column",
            color: "#1955AA",
            "& img": { width: "80%" },
          }}
        >
          <Typography variant="h3">Shortcuts</Typography>
          {smallScreen ? (
            <img src={guide} alt="guide" />
          ) : (
            <img src={smallGuide} alt="guide" />
          )}
        </Box>
      </Box>
    </Section>
  );
}

export default UserGuide;
