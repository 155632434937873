import React from "react";
import Wavesurfer from "wavesurfer.js";
import { useEffect, useRef } from "react";
import stylez from "./Waveform.module.css";

function Waveclip({ recordingData, styles }) {
  const waveform = useRef(null);

  const container = useRef(null);
  // const waveform2 = useRef(null);
  // const parentContainer = useRef(null);
  // const childContainer = useRef(null);

  // const d = useRef(null);
  useEffect(() => {
    // Checks if wavesurfer object is already created.
    if (!waveform.current) {
      // Create a wavesurfer object
      waveform.current = Wavesurfer.create({
        container: container.current,
        waveColor: "#FFF",
        // 567FFF
        barGap: 0.5,
        // barWidth: 1,
        // barHeight: 50,
        barRadius: 8,
        barMinHeight: 5,
        // cursorWidth: 0,
        interact: false,
        hideCursor: false,
        hideScrollbar: false,
        fillParent: true,
        cursorColor: "transparent",
      });

      waveform.current.load(recordingData.sourceAudio);
    }
  }, []);

  //Faulty empty space FIX
  // const Trimmed = (recordingData, styles) => {
  //   useEffect(() => {
  //     console.log(
  //       "trimmedSegment:",
  //       recordingData.recordingData.originalObj.sourceAudio
  //     );
  //     //get original(full) waveform canvas
  //     if (!waveform.current) {
  //       // Create a wavesurfer object
  //       waveform.current = Wavesurfer.create({
  //         container: parentContainer.current,

  //         waveColor: "#FFF",
  //         // 567FFF
  //         barGap: 0.5,
  //         // barWidth: 1,
  //         // barHeight: 50,
  //         barRadius: 8,
  //         barMinHeight: 5,
  //         // cursorWidth: 0,
  //         interact: false,
  //         hideCursor: false,
  //         hideScrollbar: false,
  //         fillParent: true,
  //         cursorColor: "transparent",
  //       });

  //       waveform2.current = Wavesurfer.create({
  //         container: childContainer.current,

  //         waveColor: "#FFF",
  //         // 567FFF
  //         barGap: 0.5,
  //         // barWidth: 1,
  //         // barHeight: 50,
  //         barRadius: 8,
  //         barMinHeight: 5,
  //         // cursorWidth: 0,
  //         interact: false,
  //         hideCursor: false,
  //         hideScrollbar: false,
  //         fillParent: false,
  //         cursorColor: "transparent",
  //       });

  //       // waveform2.current.load(
  //       //   recordingData.recordingData.originalObj.sourceAudio
  //       // );
  //       // console.log(waveform2.current);
  //       // waveform2.current.Drawer.clearWave();

  //       const fullCanvas = parentContainer.current.querySelector("canvas");
  //       const childCanvas = childContainer.current.querySelector("canvas");

  //       console.log(childCanvas, recordingData.recordingData.width);

  //       // d.current.style.display = "none";
  //       // childContainer.current.style.display = "none";

  //       // childCanvas.width = recordingData.recordingData.width;
  //       // childCanvas.height = 90;

  //       const fullContext = fullCanvas.getContext("2d");
  //       const childContext = childCanvas.getContext("2d");
  //       // childContext.clearRect(0, 0, childCanvas.width, childCanvas.height);
  //       // childContext.beginPath();
  //       childContext.scale(1, -1);
  //       childCanvas.width = 100;
  //       // waveform2.current.trim()
  //       // Save the current context state
  //       // childContext.save();

  //       // Flip the canvas vertically

  //       // childContext.drawImage(fullCanvas, 0, 0, 100, 100, 0, 0, 100, 100);
  //       childContext.fillRect(50, 50, 100, 100);
  //       childContext.fillStyle = "red";
  //       // childContext.scale(1, -1);
  //     }

  //   }, []);

  //   return (
  //     <>
  //       <div
  //         ref={parentContainer}
  //         className={stylez.omo}
  //         style={{ ...styles, display: "none" }}
  //       ></div>
  //       T<div ref={childContainer} className={stylez.omo} style={styles}></div>
  //     </>
  //   );
  // };
  // if (recordingData.trimmed) {
  //   return (
  //     <>
  //       {/* T<div ref={container} className={stylez.omo} style={styles}></div> */}
  //       <Trimmed recordingData={recordingData} styles={styles} />
  //     </>
  //   );
  // } else {
  //   return (
  //     <>
  //       u<div ref={container} className={stylez.omo} style={styles}></div>
  //     </>
  //   );
  // }
  return (
    <>
      <div ref={container} className={stylez.omo} style={styles}></div>
    </>
  );
}

export default Waveclip;
