import React, { useContext } from "react";
import { mainContext } from "../../services/context";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Field, Formik, ErrorMessage } from "formik";
// import { FormControl } from "react-bootstrap";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import GoogleIcon from "../../images/logos_google-icon.png";
import loginIll from "../../images/Frame.png";
import LOGO from "../../images/freshdubs.png";
import eye from "../../images/Eye.svg";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfos } from "../../store/features/userSlice";
//ADD THE ERROR MESSEGES FROM THE SERVER THING
function Login() {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { setAuth, setUserData } = useContext(mainContext);
  const navigate = useNavigate();
  const { dfghjklllkjhg } = useParams();
  // useEffect(() => {
  //   if (localStorage.getItem("jwt")) {
  //     navigate("/", { replace: true });
  //   }
  // }, []);

  useEffect(() => {
    if (dfghjklllkjhg) {
      //Google auth by default expires in 30 days

      document.cookie = `jwt_Token=${dfghjklllkjhg}; Max-Age="86400000" ; path=/; `;

      window.history.replaceState({}, "", "/");
      window.location.href = "https://fresh-dubs.com/";
    }
  }, []);
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const handleSubmit = async (values, actions) => {
    try {
      const response = await axios.post(
        "https://fresh-dubs-server.vercel.app/api/user/login",
        values,
        { withCredentials: true }
      );

      if (response.status === 200) {
        // dispatch(getUserInfos());

        document.cookie = `jwt_Token=${response.data.token} ;Max-Age=${response.data.age}  path=/;`;
        window.location.href = "https://fresh-dubs.com/";
      }

      // console.log(response.data);
      //Store data in redux
      navigate("/dashboard/projects");
    } catch (error) {
      console.error(error.response.data.message);
      setError(error.response.data.message);
    }
  };

  const handleLogin = async () => {
    // console.log("hey");
    // window.open("http://localhost:4000/api/user/google-auth", "_self");
    window.open("https://fresh-dubs-server.vercel.app/api/user/google-auth", "_self");

    // window.location.replace("http://localhost:4000/api/user/google-auth");
    // try {
    //   // const params = { HTTP_CONTENT_LANGUAGE: this.language };
    //   const headers = { "Access-Control-Allow-Origin": window.location.origin };
    //   const res = await axios.get(
    //     "http://localhost:4000/api/user/google-auth/"
    //     // { withCredentials: true }
    //   );
    //   // window.location.href = res.data.redirectUrl;
    //   console.log(res);
    // } catch (err) {
    //   console.log(err.message);
    // }
  };
  //styles
  const formStyle = {
    // display: "none",
    display: "flex",
    // width: "100%",
    flexDirection: "column",
    // backgroundColor: "#1955AA",
    color: "white",
    alignSelf: "stretch",
    // height: "100vh",
    // fontFamily: "Inter",
  };
  const formControlStyle = {
    // display: "none",
    // display: "flex",
    // flexDirection: "row",

    textAlign: "left",
    "& label": {
      mb: "8px",
      color: "white",
      fontSize: "clamp(14px,2vw,18px)",
    },

    "& .MuiOutlinedInput-root": {
      // display: "none",
      borderRadius: "25px",
      backgroundColor: "white",
      // border: "5px solid red",
      // borderRadius: "25px",
    },
    "& .MuiInputBase-input": {
      border: "none",
    },
    "& label.Mui-focused": {
      color: "white",
      // border: "1px solid white",
    },
    "& .seePassword": {
      position: "absolute",
      right: { xs: "32px", md: "24px" },
      top: { xs: "43%", md: "45%" },

      // display: "none",
    },
    "& .passwordfield": {
      // display: "none",
      width: "100%",
    },
    // "& .MuiOutlinedInput-input .Mui-focused": {
    //   border: "0px solid white",
    // },
  };
  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "stretch",
        gap: { xs: "16px", md: "64px" },
      }}
    >
      {/* {user.userName} */}
      {/* Left */}
      <Box
        sx={{
          paddingInline: "clamp(16px, 5vw, 55px)",
          paddingBottom: "clamp(80px, 7vw, 156px)",
          // paddingTop: "clamp(64px, 5vw, 72px)",
          background: "#1955AA",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
          // flexGrow: "1",

          flexBasis: { xs: "100%", md: "35%" },
          fontFamily: "Inter",
          "& #logo": {
            // display: "none",
            marginBottom: "27px",
          },
        }}
      >
        {/* <img id="logo" width="331px" height="91px" src={LOGO} alt="logo" />
        <h1
          style={{
            fontSize: "clamp(32px,5vw,40px)",
            marginTop: "0px",
            marginBottom: "16px",
            alignSelf: "start",
            fontWeight: "400",
            // textAlign:
          }}
        >
          Welcome back!
        </h1> */}

        <h3
          style={{
            fontSize: "clamp(20px,5vw,34px)",
            mb: "16px",
            fontWeight: "500",
          }}
        >
          Login
        </h3>

        {error && (
          <p style={{ color: "#ff3620", fontWeight: "700", fontSize: "24px" }}>
            {" "}
            {error}
          </p>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form style={formStyle}>
            {/* <label>HEYY</label> */}
            <FormControl sx={{ ...formControlStyle, mb: "24px" }}>
              <FormLabel>Email</FormLabel>
              <Field as={OutlinedInput} name="email" type="email" id="email" />
              <p
                style={{
                  color: "#FF9820",
                  marginTop: "6px",
                  marginLeft: "6px",
                }}
              >
                <ErrorMessage name="email" />
              </p>
            </FormControl>
            <FormControl sx={{ ...formControlStyle, mb: "20px" }}>
              <FormLabel>Password</FormLabel>
              <div>
                <Field
                  as={OutlinedInput}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="passwordfield"
                  // children
                />
                <FormControlLabel
                  className="seePassword"
                  control={
                    <img
                      // checked={field.value}
                      // onChange={field.onChange}
                      // name="rememberMe"

                      src={eye}
                      onClick={() => setShowPassword(!showPassword)}
                      alt="eye"
                    />
                  }
                  // label="see password"
                />
              </div>
              <p
                style={{
                  color: "#FF9820",
                  marginTop: "6px",
                  marginLeft: "6px",
                }}
              >
                <ErrorMessage name="password" />
              </p>
            </FormControl>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                mb: "32px",
              }}
            >
              <Field name="rememberMe">
                {({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        name="rememberMe"
                        color="primary"
                        sx={{
                          "&.MuiCheckbox-colorPrimary": {
                            color: "#FF9820",
                          },
                        }}
                      />
                    }
                    label="Remember me"
                  />
                )}
              </Field>
              {/* func to be implimented */}
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to="/forget-password"
              >
                Forget password
              </Link>
            </Stack>
            <Button
              fullWidth
              type="submit"
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "25px",
                padding: "14px 32px",
                mb: "46px",

                "&:hover": {
                  backgroundColor: "#011837",
                },
              }}
            >
              Login
            </Button>
            <h2
              style={{
                width: "100%",
                textAlign: "center",
                borderBottom: "1px solid #fff",
                lineHeight: "0.1em",
                margin: "10px 0 20px",
                marginBottom: "clamp(19px, 2vw, 30px)",
              }}
            >
              <span
                style={{
                  padding: "0 20px",
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "white",
                  background: "#1955AA",
                }}
              >
                or
              </span>
            </h2>

            <Button
              fullWidth
              // type="submit"
              // disableElevation={true}
              onClick={handleLogin}
              sx={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "25px",
                padding: "14px 32px",
                mb: "46px",
                border: "2px solid white",

                "&:hover": {
                  backgroundColor: "white",
                  // border: "2px solid black",
                },
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",

                  "& p": {
                    margin: "0px",
                    marginLeft: "12px",
                  },
                }}
              >
                <img
                  // style={{ height: width: "22.5px" }}
                  width="25px"
                  height="25px"
                  src={GoogleIcon}
                  alt="google icon"
                />
                <p>Continue with Google</p>
              </Stack>
            </Button>
            <Link style={{ color: "white" }} to={"/signup"}>
              Register here
            </Link>
          </Form>
        </Formik>
      </Box>

      {/* Right */}
      <Box
        sx={{
          padding: "20px",
          // display: "flex",
          alignItems: "center",
          justifyContent: "center",
          display: { xs: "none", md: "flex" },
          "& img": {
            width: "100%",

            // height: "800px",
          },
        }}
      >
        <img src={loginIll} alt="login" />
      </Box>
    </Stack>
  );
}

export default Login;
