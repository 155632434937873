export const pricingOptions = [
  {
    plan: "Basic",
    price: "0",
    desc: "Get FreshDub’s basic plan",
    core: "Basic",
    listings: [
      "Videos up to 1:30 mins",
      "Integrated audio recorder",
      "Export as MP3",
    ],
    blue: true,
    price_id: "login",
  },
  {
    plan: "Pro",
    price: "17",
    desc: "Try for free for 10 days",
    core: "All the features of the FREE plan + ",
    listings: [
      "Unlimited length videos",
      "RythmoBand volume of 100 mins",
      "Import subtitles",
      "Export your RythmoBand",
    ],
    blue: false,
    price_id_test: "price_1N4TmTFQPhdwK6Sok1b9kNeE",
    price_id: "price_1N4RguFQPhdwK6SoT1ZBiQTT",
  },
  {
    plan: "Expert",
    price: "37",
    desc: "Try for free for 10 days",
    core: "All the features of the Pro plan +",
    listings: ["Unlimited RythmoBand volume", "Export audio as WAV"],
    blue: true,
    price_id_test: "price_1N4TpWFQPhdwK6SogFXUZ6AZ",
    price_id: "price_1N4RipFQPhdwK6SoGey1damC",
  },
];
// plan, price, desc, core, listings, blue
