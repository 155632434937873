import {
  Card,
  OutlinedInput,
  FormControl,
  FormLabel,
  Button,
} from "@mui/material";
import React from "react";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import LOGO from "../../images/freshdubs.png";
import { useState } from "react";

function Forget() {
  const [sent, setSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const formControlStyles = {
    "& label": {
      textAlign: "left",
    },
  };
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });
  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://fresh-dubs-server.vercel.app/api/user/forget-password",
        values,
        { withCredentials: true }
      );
      //   setUserData(response.data);
      if (response) {
        // console.log(response.data);
        setLoading(false);
        document.cookie = `resetPass=${response.data.token}; Max-Age="900000" ; path=/; `;
        setSent(true);
        setErrorMsg("");
      }
      //Change state of page to reflect sent email
    } catch (error) {
      console.error(error.response.data);
      setErrorMsg(error.response.data.message);
      setSent(false);
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#1955AA",
      }}
    >
      <Card
        sx={{
          padding: "32px",
          //   width: "fit-content",
          width: { xs: "70%", md: "60%", lg: "600px" },
          "& button": {
            mt: "16px",
          },
        }}
        elevation={10}
      >
        <img src={LOGO} alt="logo" />
        <h3>Type your email</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl sx={{ ...formControlStyles }}>
              <FormLabel>Email</FormLabel>
              <Field
                as={OutlinedInput}
                name="email"
                type="email"
                id="email"
                size="medium"
              />
              <p color="#FF9820">
                <ErrorMessage name="email" />
              </p>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              sx={{ padding: "16px" }}
              disabled={loading}
            >
              Submit Email
            </Button>
          </Form>
        </Formik>

        {/* <form>
        <label>Email</label>
        <input type="text" />
      </form> */}
        {sent && (
          <p style={{ color: "green" }}>
            Recovery link has been sent to your email, expires in{" "}
            <strong style={{ textDecoration: "underline" }}>15mins</strong>
          </p>
        )}
        {errorMsg && (
          <p>
            {" "}
            <p style={{ color: "red" }}>{errorMsg}</p>
          </p>
        )}
      </Card>
    </div>
  );
}

export default Forget;
