import { notify } from "reapop";
import { saveProject, uploadProject } from "../store/features/appSlice";
import store from "../store/store";

var raw_lines = [];
var currentTime, dial_number, rythmo_position;
export const loadFile = async (file) => {
  const dialogue = [];

  const time = [];
  time.length = 0;
  dialogue.length = 0;
  raw_lines.length = 0;

  let text = await file.text();
  raw_lines = text.split(/\r\n|\n/);
  raw_lines = raw_lines.filter((e) => e != "");
  raw_lines = raw_lines.filter((x) => {
    return x.indexOf('.') !== -1 || isNaN(x);
  });

  for (var i = 0; i < raw_lines.length; i++) {
    raw_lines[i] = raw_lines[i].trim();
  }

  for (var i = 0; i < raw_lines.length - 1; i++) {
    let check_d1 = raw_lines[i].search("-->");
    let check_d2 = raw_lines[i + 1].search("-->");
    // if(isNaN(raw_lines[i][0]) && isNaN(raw_lines[i+1][0]))
    // {
    //  raw_lines[i] = raw_lines[i] +" "+ raw_lines[i+1];
    //  raw_lines.splice(i+1,1);
    // }
    if (check_d1 == -1 && check_d2 == -1) {
      raw_lines[i] = raw_lines[i] + " " + raw_lines[i + 1];
      raw_lines.splice(i + 1, 1);
      i = 0;
    }
  }

  for (var i = 0; i < raw_lines.length; i++) {
    if (i % 2 == 0) {
      time.push(raw_lines[i]);
    } else {
      dialogue.push(raw_lines[i]);
    }
  }

  raw_lines.length = 0;

  for (var i = 0; i < time.length; i++) {
    time[i] = time[i].split("-->");
    // console.log(time[i]);
    time[i][0] = time[i][0].replace(",", ":");
    time[i][1] = time[i][1].replace(",", ":");
  }
  for (var i = 0; i < time.length - 1; i++) {
    if (getSub_Millis(time[i + 1][0]) - getSub_Millis(time[i][1]) < 50) {
      const timeDiff =
        getSub_Millis(time[i + 1][0]) - getSub_Millis(time[i][1]);
      const timeToAdd = Math.ceil((50 - timeDiff) / 2);

      time[i][1] = adjustTime(time[i][1], -timeToAdd);
      time[i + 1][0] = adjustTime(time[i + 1][0], timeToAdd);
    }
  }

  return { time, dialogue };
};
function adjustTime(timeStr, milliseconds) {
  const timeInMillis = getSub_Millis(timeStr);
  const adjustedTimeInMillis = timeInMillis + milliseconds;

  // Convert adjusted time in milliseconds back to the time string format
  const hours = Math.floor(adjustedTimeInMillis / 3600000);
  const minutes = Math.floor((adjustedTimeInMillis % 3600000) / 60000);
  const seconds = Math.floor((adjustedTimeInMillis % 60000) / 1000);
  const millis = adjustedTimeInMillis % 1000;

  return `${formatTimeComponent(hours)}:${formatTimeComponent(
    minutes
  )}:${formatTimeComponent(seconds)}:${formatTimeComponent(millis)}`;
}

function formatTimeComponent(component) {
  // Helper function to format time components with leading zeros if necessary
  return component.toString().padStart(2, "0");
}
export const makeSubs = () => {
  const time = [];
  for (var i = 0; i < time.length; i++) {
    // document.getElementById("output").innerHTML +=
    //   time[i] + "\n" + dialogue[i] + "\n";
  }
};
export const getSub_Seconds = (param) => {
  /*
		Pass this function the string format of time parameter and it will return you the 
		time converted to seconds in integer format
		*/
  param = param.split(":");
  var hours = parseInt(param[0]);
  var minutes = parseInt(param[1]);
  var seconds = parseInt(param[2]);
  var millis = parseInt(param[3]);

  var total_seconds = hours * 3600 + minutes * 60 + seconds + millis / 1000;
  return total_seconds;
};

export const getSub_Millis = (param) => {
  /*
		Pass this function the string format of time parameter and it will return you the 
		time converted to milliseconds in integer format
		*/
  param = param.split(":");
  var hours = parseInt(param[0]);
  var minutes = parseInt(param[1]);
  var seconds = parseInt(param[2]);
  var millis = parseInt(param[3]);

  var total_millis =
    hours * 3600000 + minutes * 60000 + seconds * 1000 + millis;
  return total_millis;
};

export const loadJson = async (file) => {
  // var loaded_Json_data = await file.text();
  var encodedData = file.split(",")[1];

  // Decode the URI component
  var decodedData = decodeURIComponent(encodedData);

  // Parse the JSON data
  // var loadedData = JSON.parse(decodedData);
  var decoded = JSON.parse(decodedData);

  //Old Structure
  // dial_number = decoded["number"];
  // rythmo_position = decoded["position"];
  return decoded;
};

export const PrintJson = () => {
  document.getElementById("output").innerHTML =
    " " + currentTime + " " + dial_number + " " + rythmo_position;
};

export const MakeJson = (currentTime, savedRecords) => {
  // console.log(savedRecords);
  //New structure
  var dict = [
    ...savedRecords,
    {
      currentTime: currentTime,

      // number: dial_number,
      // position: rythmo_position,
    },
  ];

  var data =
    "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dict));

  // return data;
  store.dispatch(saveProject(data));
  store
    .dispatch(uploadProject(data))
    .then(store.dispatch(notify({ message: "Project Saved" })))
    .catch((error) => {
      store.dispatch(notify({ message: error.response.data }));
      console.log(error);
    });
  // var a = document.createElement("a");
  // a.href = "data:" + data;
  // a.download = "data.json";
  // a.innerHTML = "";

  // var container = document.getElementById("container");
  // container.appendChild(a);
  // a.click();
  // container.removeChild(a);
};
var hours,
  mins,
  secs,
  mills,
  d = 0;

export const ToSrtTime = (value) => {
  if (Number.isInteger(value)) {
    d = value;
  } else {
    var decimalStr = value === 0 ? value.toString().split(".")[1] : 0;
    var dec = Number(decimalStr);
  }

  d = Number(value);
  hours = Math.floor(d / 3600);
  mins = Math.floor((d % 3600) / 60);
  secs = Math.floor((d % 3600) % 60);
  if (getlength(dec) === 1) {
    mills = dec * 100;
  } else if (getlength(dec) == 2) {
    mills = dec * 10;
  } else {
    mills = dec;
  }
  return `${hours}:${mins}:${secs}:${mills}`;
};
export const getlength = (number) => {
  return number?.toString().length;
};
