async function calculateTextByteLength(text) {
  const encoder = new TextEncoder();
  return encoder.encode(text).length;
}

async function translateWithOpenAI(text, targetLanguage) {
  const apiKey = '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';
  const url = 'https://api.openai.com/v1/chat/completions'; // Use the appropriate endpoint for non-chat models

  const requestData = {
    messages: [
      {
        role: 'system',
        content: 'You are a helpful assistant that translates text into targetLanguage.',
      },
      {
        role: 'user',
        content: `Translate the following text into ${targetLanguage}: "${text}"`,
      },
    ],
    max_tokens: 100, // You can adjust this as needed
    model: 'gpt-3.5-turbo-0613',
  };


  const headers = {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json',
  };

  try {
    const byteLength = await calculateTextByteLength(text);

    if (requestData.max_tokens > byteLength) {
      requestData.max_tokens = byteLength;
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    console.log(responseData)
    const translatedText = responseData.choices[0].message.content;

    return translatedText;
  } catch (error) {
    throw error;
  }
}

export default async function translateSubtitle(subtitle = [], targetLanguage) {
  const translatedSubtitle = [];

  for (const item of subtitle) {
    const translatedText = await translateWithOpenAI(item.text, targetLanguage);
    translatedSubtitle.push({ ...item, text: translatedText });
  }

  return translatedSubtitle;
}
