import React from "react";
import Section from "../components/Section/section";
import { Box, Button, Typography } from "@mui/material";
import Lines from "../images/lines.png";
import pblob1 from "../images/pblob1.png";
import pblob2 from "../images/pblob2.png";
import pblob3 from "../images/pblob3.png";
import abou from "../images/About.png";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();
  const handleLearnMore = () => {
    navigate("/faq");
  };
  const aboutStyles = {
    "& .pblob1, .pblob2, .pblob3": {
      position: "absolute",
      zIndex: "-1",
    },

    "& .pblob2": {
      bottom: "0px",
      right: "10px",
    },
    "& .pblob3": {
      bottom: "-10%",
      left: "30%",
    },
    "& .pblob1": {
      bottom: "50%",
      right: "-50px",
    },
    display: "flex",
    alignItems: "center",
    minHeight: "inherit",
    paddingTop: "36px",
    paddingBottom: "100px",
    // justifyContent: "center",
    // height: "90vh",
    //     ".article":{
    // height:"fit"
    //     },

    "& article": {
      flexBasis: { xs: "100%", md: "50%" },
      "& h2": {
        // display: "none",
        fontSize: "clamp(32px, 5vw, 40px)",
        // color: "#1955AA",
        fontWeight: "400",
        margin: "0px",
      },

      "& p": {
        fontSize: "clamp(16px, 5vw, 24px)",
      },

      ".title": {
        width: { xs: "80%", md: "300px" },
        display: "flex",
        flexDirection: "column",
        //   justifyContent: "center",
        alignItems: { xs: "center", md: "start" },
        "& h1": {
          // display: "none",
          fontSize: "clamp(32px, 5vw, 40px)",
          color: "#1955AA",
          fontWeight: "400",
          margin: "0px",
          paddingLeft: "16px",
        },
        "& img": {
          width: "100%",
        },
      },
    },
    "& .abou": {
      display: { xs: "none", md: "block" },
      flexBasis: "50%",
      "& img": {
        width: "100%",
      },
    },
  };
  return (
    <Section sx={aboutStyles}>
      <img src={pblob1} className="pblob1" alt="blob1" />
      <img src={pblob2} className="pblob2" alt="blob2" />
      <img src={pblob3} className="pblob3" alt="blob3" />
      <article className="article">
        <Box className="title">
          <Typography variant="h1">About us</Typography>
          <img src={Lines} alt="" className="lines" />
        </Box>
        <div>
          <h2>We believe that every video has the potential</h2>
          <p>
            We take pride in the quality of our work, and we're committed to
            delivering results that exceed your expectations. Our team uses the
            latest technology and techniques to create FreshDubs that are both
            visually stunning and engaging. We're always pushing ourselves to be
            better, and we're constantly exploring new ideas and approaches to
            video editing.
          </p>
          <Button
            sx={{
              backgroundColor: "#4765F8",
              fontSize: "14px",
              padding: "8px",
              "&:hover": {
                backgroundColor: "#1955AA",
              },
            }}
            variant="contained"
            onClick={handleLearnMore}
          >
            Learn More
          </Button>
        </div>
      </article>

      <Box className="abou">
        <img src={abou} alt="about" />
      </Box>
    </Section>
  );
}

export default About;
