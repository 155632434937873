import React, { useContext } from "react";
import Section from "../../components/Section/section";
import { Box } from "@mui/material";
import projectIcon from "../../images/projectIcon.png";
import cross from "../../images/cross.png";
import { projectList } from "../../utils/dummyData";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  clearProject,
  getAllProjects,
  loadProject,
} from "../../store/features/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { DialoguesContext, mainContext } from "../../services/context";

function Projects() {
  const { projects } = useSelector((state) => state.app);
  const { setRecordingData, setprojectLoaded } = useContext(mainContext);

  const { setCurrentTime } = useContext(DialoguesContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  const navigate = useNavigate();
  const handleProjectLoad = (projectName, id) => {
    setRecordingData([]);
    setCurrentTime("");
    setprojectLoaded(false);
    dispatch(clearProject());
    if (id) {
      dispatch(loadProject(id));
    }
    navigate(`/app/${projectName}`);
  };
  //Project file
  const Project = ({ projectName, id }) => {
    return (
      <>
        <Box
          sx={{
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "8px",
            width: "200px",
            height: "200px",
            "&:hover": {
              boxShadow: "0 0 1px rgba(4, 4, 4, 0.897)",
            },
            "& p": {
              margin: "0px",
            },
            "& img": {
              width: "80%",
            },
          }}
          onClick={() => handleProjectLoad(projectName, id)}
        >
          <img src={projectIcon} alt="" />
          <p>{projectName}.mp4</p>
        </Box>
      </>
    );
  };
  return (
    <Box
      sx={{
        width: "100%",
        padding: "32px",
        gap: "24px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        backgroundColor: "white",
        minHeight: "100vh",
        alignItems: "start",
        alignContent: "start",
        borderRadius: "16px",
        justifyContent: { xs: "center", md: "normal" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          //   justifyContent: "center",
          alignItems: "center",
          padding: "64px",
          paddingInline: "16px",
          paddingBottom: "16px",
          border: "2px dashed #1955AA",
          borderRadius: "8px",
          width: "200px",
          height: "200px",
          backgroundColor: "#1955AA1A",
          cursor: "pointer",
          "& span": {
            color: "#1955AA",
          },
          "& img": {
            width: "50px",
          },
          "&:hover": {
            backgroundColor: "#1955aa2d",
          },
        }}
        onClick={() => handleProjectLoad("new")}
      >
        <img src={cross} alt="add project" />
        <p>
          Create a <span>Project</span>
        </p>
      </Box>
      {projects?.map((project) => {
        return (
          <>
            <Project projectName={project.projectName} id={project._id} />
          </>
        );
      })}
    </Box>
  );
}

export default Projects;
