import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import pblob1 from "../../images/marker.png";
import pricingIcon from "../../images/priceIcon.png";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function PricingCard({ plan, price, desc, core, listings, blue, price_id }) {
  const { user } = useSelector((state) => state.user);
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const parentCardStyles = {
    display: "flex",
    flexDirection: "column",
    position: { md: blue ? "relative" : "absolute" },
    left: { md: !blue ? "50%" : "" },
    top: { md: !blue ? "-10%" : "" },
    transform: { md: !blue && "translateX(-50%)" },
    zIndex: { md: !blue && "99" },
    alignItems: "center",
    color: "white",
    backgroundColor: blue ? "rgba(24, 82, 163, 1) " : "rgba(255, 152, 32, 1)",
    // padding: "80px",
    borderRadius: "13px",
    padding: {
      xs: "clamp(24px, 8vw, 72px)",
      md: blue ? "clamp(24px, 8vw, 72px)" : "clamp(24px, 4vw, 72px)",
    },
    // paddingInline: "16px",
    // maxWidth: "400px",
    width: { xs: "300px", md: "clamp(300px, 7vw, 400px)" },
    "& h2": {
      //   display: "none",
      fontSize: "28px",
    },
  };
  const listingStyles = {
    // display: "none",
    marginTop: "clamp(30px, 5vw,50px)",
    "& h2": {
      fontSize: "clamp(16px, 5vw, 21px)",
      fontWeight: "600",
      marginBottom: "14px",

      //   display: "none",
    },
    "& p": {
      fontSize: "clamp(14px, 3vw, 18px)",
      fontWeight: "500",
      margin: "0px",
      marginBottom: "clamp(16px, 5vw, 32px)",

      //   display: "none",
    },
  };
  const listStyles = {
    // display: "none",
    listStyleImage: `url(${pblob1})`,
    fontSize: "clamp(15px,5vw, 17px)",
    margin: "0px",
    paddingInline: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "clamp(16px, 4vw, 21px)",
  };
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const handlePayment = async (price_id) => {
    if (price_id === "login" || !getCookie("jwt_Token")) {
      navigate("/login");
      return;
    }
    try {
      const stripe = await loadStripe("pk_live_MlcHUk6FkGY2sItosDkjyIRE");
      // console.log(price_id, user.customerId);
      const body = { product: price_id, customerId: user.customerId };
      const headers = {
        "Content-Type": "application/json",
      };
      setClicked(true);
      const response = await fetch(
        // "https://fresh-dubs-server.vercel.app/api/stripe/create-checkout-session",
        "https://fresh-dubs-server.vercel.app/api/stripe/create-checkout-session",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
          // credentials: "true",
        }
      );
      setClicked(false);

      const session = await response.json();
      // console.log(session);

      const result = stripe.redirectToCheckout({
        sessionId: session.id,
      });
      window.location.href = session.url;

      if (result.error) {
        console.error(result.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Card sx={parentCardStyles}>
      <Box sx={{ width: "fit-content" }}>
        <Box
          sx={{
            //   display: "flex",
            //   flexDirection: "column",
            //   gap: "16px",

            "& h3": {
              // display: "none",
              fontSize: "clamp(12px, 7vw,18.39px )",
              marginBottom: "16px",
            },
            "& h1": {
              fontSize: "clamp(32px, 7vw,48.5px )",
              fontWeight: "900",
              position: "relative",
              marginRight: "6px",

              "& span": {
                position: "absolute",
                top: "0",
                left: "-9px",
                //   bottom: "100%",
                //   display: "none",
                fontSize: "clamp(10px,5vw,14px)",
              },
            },
            "& p": {
              margin: "0px",
              // display: "none",
              fontSize: "clamp(11px,5vw,15.5px)",
              color: "rgba(255, 255, 255, 0.8)",
            },
          }}
        >
          <Typography variant="h3">{plan}</Typography>

          <Box
            sx={{
              borderBottom: "2px solid rgba(255, 255, 255, 0.1)",
              paddingBottom: "16px",
              marginBottom: "16px",
              margingRight: "2px",
            }}
          >
            <Typography variant="h1" display="inline">
              <span>£</span>
              {price}
            </Typography>
            <p style={{ display: "inline", color: "rgba(255, 255, 255, 0.8)" }}>
              /Month
            </p>
          </Box>

          <p>{desc}</p>
        </Box>
        <Box sx={listingStyles}>
          <Typography variant="h2">Core Features</Typography>
          <p>{core}</p>

          <ul style={listStyles}>
            {listings.map((listing) => (
              <li>{listing}</li>
            ))}
          </ul>
        </Box>
        <Button
          variant="contained"
          size="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            gap: "8px",
            backgroundColor: blue
              ? "rgba(255, 152, 32, 1)"
              : "rgba(24, 82, 163, 1) ",

            padding: !blue ? "14px 28px" : "14px 0px",
            borderRadius: "8px",
            width: "100%",
            marginTop: "clamp(30px, 5vw,50px)",
            "&:hover": {
              backgroundColor: blue && "#ffa135",
            },
          }}
          disabled={clicked}
          onClick={() => handlePayment(price_id)}
        >
          Get Started
          <img src={pricingIcon} alt="" />
        </Button>
      </Box>
    </Card>
  );
}

export default PricingCard;
