import React, { Component } from "react";
import { Player, ControlBar } from "video-react";
import Button from "@mui/material/Button";
import Rythmoband from "../rythmoband/rythmoband";
import { getSub_Seconds, MakeJson, ToSrtTime } from "../../services/srtreader";
import { convertToSecInt } from "../../services/timeFunctions";
// import AudioPlayer from "../AudioPlayer";
import TimeSlider from "../TimeSlider";
import { mainContext, DialoguesContext } from "../../services/context";
import { height } from "@mui/system";
import myWorker from "../../services/videoRendering/videoRender.worker";
import RecordRTC from "recordrtc";
import WorkerBuilder from "../../services/videoRendering/worker-builder";
import { Provider, connect } from "react-redux";
import store from "../../store/store";
import { Box, IconButton } from "@mui/material";

import Editor from "../Editor";
// import Editor from "../Editor";
// import { mainContext, DialoguesContext } from "../App";

const sources = {
  sintelTrailer: "http://media.w3.org/2010/05/sintel/trailer.mp4",
  bunnyTrailer: "http://media.w3.org/2010/05/bunny/trailer.mp4",
  bunnyMovie: "http://media.w3.org/2010/05/bunny/movie.mp4",
  test: "http://media.w3.org/2010/05/video/movie_300.webm",
};
// console.log(mainContext.Consumer);
const mapStateToProps = (state) => {
  const vidSource = state.vidSource;
  const name = state.projectName;
  // const text = state.text;
  return {
    vidSource,
    name,
    // text
  };
};
class VideoPlayer extends Component {
  static contextType = mainContext.Consumer;

  constructor(props, context) {
    super(props, context);
    this.state = {
      paused: true,
      initialPlay: true,
      source: this.props.source,
      name: this.props.projectName,
      player: { currentTime: " ", playbackRate: " " },
      windowWidth: window.innerWidth,
      generated: true,
      timer: 0,
      isRecording: false,
      jsonArray: [],
      generating: false,
      progress: 0,
      vidId: 0,
      pauseRender: true,
      zoomPercent: ((window.outerWidth - 10) / window.innerWidth) * 100,
    };
    // const [zoomPercent, setzoomPercent] = useState(
    //   ((window.outerWidth - 10) / window.innerWidth) * 100
    // );
    // this.generated = React.createRef(true);
    this.myWorker = new WorkerBuilder(myWorker);
    this.vidd = React.createRef();
    this.vidProg = React.createRef(null);
    this.vidId = React.createRef(null);
    this.lastTime = React.createRef(0);
    this.counter = React.createRef(0);
    this.timeGen = React.createRef();
    this.params = React.createRef();
    this.vidCanva = React.createRef();
    this.recorder = React.createRef();
    this.text = React.createRef(166);
    this.video = React.createRef();
    this.play = this.play.bind(this);
    this.save = this.save.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.changeCurrentTime = this.changeCurrentTime.bind(this);
    this.seek = this.seek.bind(this);
    this.seeker = this.seeker.bind(this);
    this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.onSeekbarChange = this.onSeekbarChange.bind(this);
    this.handleLoadedMetadata = this.handleLoadedMetadata.bind(this);
    this.downloadBlob = this.downloadBlob.bind(this);
    this.calculateTextParams = this.calculateTextParams.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.updateParent = this.updateParent.bind(this);

    // this.handleShortcut = this.handleShortcut.bind(this);
  }

  // shouldComponentUpdate(nextProps) {
  //   // Check if the 'yourProp' prop has changed
  //   if (nextProps.source !== this.props.source) {
  //     // return true; // Trigger re-render
  //     // console.log("yeah");
  //     return true;
  //   }
  //   console.log("no");
  //   return false; // Do not re-render
  // }

  componentDidMount() {
    // const { toggleSlider, setToggleSlider } = this.context;
    // console.log(
    //   "toggleSlider.........................",
    //   this.props.toggleSlider
    // );
    // console.log("ORMO", mainContext.Consumer);

    // subscribe state change
    // console.log("No source", this.props.source.payload);
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    window.addEventListener("resize", this.handleResize);
    // console.log(this.myWorker);

    this.myWorker.onmessage = (message) => {
      console.log("reply from worker: ", message);
    };

    // const video = document.createElement("video");
    // const canvas = document.getElementById("myVid");
    const canvas = this.vidCanva.current;

    // video.src = this.state.source;
    canvas.width = 1280;
    canvas.height = 920;

    const ctx = canvas.getContext("2d");
    this.text.current = -140;
    // setInterval(() => {
    //   this.text.current += 2;
    // }, 0.1);
    this.vidd.current.addEventListener("play", () => {
      // video.play();
      // this.video.muted = true;

      const time1 = [];
      for (let i = 0; i < this.props.time1.length; i++) {
        time1[i] = [
          convertToSecInt(this.props.time1[i][0]),
          convertToSecInt(this.props.time1[i][1]),
        ];
      }
      this.timeGen.current = time1;
      this.calculateTextParams();

      this.drawFrame(this.vidd.current, ctx);
      const PIXELS_WIDTH = 200; // Width multiplier
      const FONT_SIZE = 22;
      //creating TextCanvas
      // const textCanvas = document.getElementById("textCanvas");
      // textCanvas.setAttribute("id", "textCanvas");

      // textCanvas.width = 2465;

      //loop over dialogue
      // console.log(this.state.generated);
      if (this.state.generated) {
        // console.log(this.state.generated);
        // this.generated.current = false;
      }
      this.setState({ ...this.state, generated: false });
      if (this.state.isRecording) {
        return;
      } else {
        this.recorder.current.startRecording();
        this.setState({ ...this.state, isRecording: true });
      }
    });

    this.vidd.current.addEventListener("loadedmetadata", () => {
      const canvasStream = canvas.captureStream(30);
      // const audioTrack = this.vidd.current.src.getAudioTracks();

      // const audioStream = this.vidd.current.captureStream().getAudioTracks();
      // console.log("HAUUUEEE", audioStream);
      // canvasStream.addTrack(audioStream[0]);
      // console.log(canvasStream.addTrack);

      const audioCtx = new AudioContext();
      const sourceNode = audioCtx.createMediaElementSource(this.vidd.current);
      const audioStream = sourceNode
        .connect(audioCtx.createMediaStreamDestination())
        .stream.getAudioTracks();
      canvasStream.addTrack(audioStream[0]);
      // const fullStream = new MediaStream([
      //   ...audioStream,
      //   ...canvasStream.getTracks(),
      // ]);
      const recorder = RecordRTC(canvasStream, {
        type: "video",
        mimeType: "video/mp4",
        video: {
          width: 1280,
          height: 720,
          frameRate: 30,
        },
        canvas: {
          width: 1280,
          height: 920,
        },
      });
      this.recorder.current = recorder;
    });
    this.vidd.current.addEventListener("ended", () => {
      // video.play();
      // clearInterval(this.vidId.current);

      this.recorder.current.stopRecording(() => {
        // console.log("stoppedVIDEO");
        const blob = this.recorder.current.getBlob();
        this.downloadBlob(blob, "mySlightlySadDemo");
        this.setState({ ...this.state, isRecording: false });
        // this.recorder.current.getInternalRecorder().addAudio();
      });
    });
    this.vidd.current.addEventListener("pause", () => {
      // clearInterval(this.state.vid);
      if (this.state.pauseRender) {
        // return;
        this.recorder.current.pauseRecording();
      } else {
        this.recorder.current.stopRecording(() => {
          // console.log("stoppedVIDEO");
          const blob = this.recorder.current.getBlob();
          this.downloadBlob(blob, "myDemo");
          this.setState({
            ...this.state,
            isRecording: false,
            pauseRender: true,
          });
          this.vidd.current.currentTime = 0;
          // this.recorder.current.getInternalRecorder().addAudio();
        });
      }
      clearInterval(this.vidId.current);
    });
    // this.video.current = video;
    //   componentDidUpdate(this.player) {
    //   if (this.state.show !== prevState.show) {
    //     console.log("Do something");
    //   }
    // }
  }
  componentDidUpdate() {
    // console.log("help");
    if (this.props.vidHot.play) {
      // console.log("TO PLAY");
      this.player.play();
    } else {
      this.player.pause();
    }

    if (this.props.vidHot.save) {
      console.log(this.props.vidHot.save);
      this.save();

      this.props.setVidHot({ ...this.props.vidHot, save: false });
    }

    // if (!this.state.generating) {
    //   clearInterval(this.vidId.current);
    // }
    if (this.state.generating && !this.state.vidId) {
      // console.log("in the conditional");
      const vidId = setInterval(() => {
        // console.log(this.vidProg.current);
        this.vidProg.current.textContent = this.formatTime(
          this.vidd.current.currentTime
        );
      }, 1000);
      console.log("THE VID ID", vidId);
      this.setState({ vidId: vidId });
    }
    if (!this.state.generating && this.state.vidId) {
      console.log(this.state.vidId);
      clearInterval(this.state.vidId);
      this.setState({ ...this.state, vidId: 0 });
    }
  }

  componentWillUnmount() {
    clearInterval(this.vidId.current);
    window.removeEventListener("resize", this.handleResize);
    // this.context.setCurrentTime("");
    // this.context.setRecordingData([]);
  }

  drawFrame(video, ctx, time) {
    const elapsed = time - this.lastTime.current;

    // console.log("heyy", this.props.time1, this.props.Dialogues1);

    // let rythmoText = document.getElementById("textCanvas");
    const velocity = 1;
    if (video.paused || video.ended) {
      // this.recorder.current.stopRecording(() => {
      //   console.log("stoppedVIDEO");
      //   const blob = this.recorder.current.getBlob();
      //   this.downloadBlob(blob);
      //   this.setState({ ...this.state, isRecording: false });
      // });
      return;
    }

    ctx.drawImage(video, 0, 0, 1280, 720);
    // console.log(this.params.current.length);
    // ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "orange";
    //810 - 90
    ctx.fillRect(0, 920 - 200, 1280, 200);
    //redLine0
    ctx.fillStyle = "red";
    ctx.fillRect(130, 920 - 200, 16, 200);

    for (let i = 0; i < this.props.Dialogues1.length; i++) {
      // console.log("sec", this.params.current[309].x);
      const { fontSize, x, width, letterSpacing } = this.params.current[i];
      ctx.fillStyle = "black";
      ctx.font = `bold ${fontSize}px Arial`;
      // ctx.fon
      //660
      ctx.letterSpacing = `${letterSpacing * 660}px`;
      ctx.fillText(
        this.props.Dialogues1[i],
        //2100
        x * 350 - video.currentTime * 350 + 150,
        //45 for 90
        920 - 100
      );
    }
    // this.vidProg.current = this.formatTime(video.currentTime)
    // this.setState({
    //   ...this.state,
    //   progress: video.currentTime / video.duration,
    // });
    requestAnimationFrame((timestamp) => this.drawFrame(video, ctx, timestamp));
  }

  calculateTextParams() {
    const params = [];
    for (let i = 0; i < this.props.Dialogues1.length; i++) {
      const elContainerWidth =
        (this.timeGen.current[i][1] - this.timeGen.current[i][0]) * 200;
      const elTextWidth = this.props.Dialogues1[i].length * 10;
      let fontSize = 22;
      if (elContainerWidth < elTextWidth) {
        let diff = elTextWidth - elContainerWidth;
        diff = diff > 100 ? 100 : diff;
        fontSize = 23 - Math.round(diff / 20);
      }
      let x = this.timeGen.current[i][0];
      let width =
        (this.timeGen.current[i][1] - this.timeGen.current[i][0]) /
        (this.vidd.current.duration / 100);
      let letterSpacing = width / this.props.Dialogues1[i].length;
      params.push({ fontSize, x, width, letterSpacing });
    }
    this.params.current = params;
  }
  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  setMuted(muted) {
    return () => {
      this.player.muted = muted;
    };
  }

  // Shortcut Video Player Logic
  handleLoadedMetadata() {
    const video = this.vidd.current;
    if (!video) return;
    // console.log(`The video is ${video.duration} seconds long.`);
    this.context.setVidDuration(video.duration);
  }
  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }
  play() {
    // this.props.setToggleSlider(true);
    this.props.setVidHot({ ...this.props.vidHot, play: true });
    this.setState({ paused: false });
    this.player.play();
    this.setState({
      timer: 5000000,
    });
    if (this.state.initialPlay) {
      this.setState({ initialPlay: false });
      this.player.seek(
        this.props.initialTime === undefined
          ? 0
          : getSub_Seconds(this.props.initialTime)
      );
    }
  }

  pause() {
    // this.props.setToggleSlider(false);
    // this.myWorker.postMessage("your rent is due");
    // console.log(this.myWorker);
    this.props.setVidHot({ ...this.props.vidHot, play: false });
    this.setState({ paused: true });
    this.player.pause();
    console.log(this.player.video.video.paused);
    this.setState({
      timer: 0,
    });
    // this.setToggleSlider(false);
  }
  load() {
    this.player.load();
  }
  save() {
    if (this.player) {
      const { player } = this.player.getState();
      let savedRecords = [];
      for (let x of this.context.recordingData) {
        // x.
        const save = Object.assign(
          {},
          {
            sourceAudio: x.sourceAudio,
            startTime: x.startTime,
            endTime: x.endTime,
          }
        );
        savedRecords.push(save);
      }
      console.log("THE LENGTH", this.context.recordingData.length);
      MakeJson(
        //NEW STRUCTURE

        ToSrtTime(player.currentTime),
        this.context.recordingData

        //OLD STRUCTURE
        // this.props.dialogueNumber,
        // this.props.rythmoPosition,
      );
    }
  }
  changeCurrentTime(seconds) {
    return () => {
      const { player } = this.player.getState();
      this.player.seek(player.currentTime + seconds);
    };
  }
  formatTime(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
    const seconds = Math.floor(timeInSeconds - hours * 3600 - minutes * 60);
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(seconds).padStart(2, "0");
    if (hours > 0) {
      return `${hours}:${paddedMinutes}:${paddedSeconds}`;
    }
    return `${minutes}:${paddedSeconds}`;
  }
  getCurrentTime() {
    return () => {
      const { player } = this.player.getState();
      return player.currentTime;
    };
  }
  seek(seconds) {
    return () => {
      this.player.seek(seconds);
    };
  }
  changePlaybackRateRate = (steps) => {
    // return () => {
    const { player } = this.player.getState();
    this.player.playbackRate = player.playbackRate + steps;
    // };
  };
  changeVolume(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.volume = player.volume + steps;
    };
  }
  changeSource(name) {
    return () => {
      this.setState({
        source: sources[name],
      });
      this.player.load();
    };
  }
  seeker = (seconds) => {
    if (this.player) {
      const { player } = this.player.getState();
      this.player.seek(player.currentTime + seconds);
    }
    // };
  };
  muteVideo(flag) {
    this.player.muted = flag;
  }
  onSeekbarChange = (seconds) => {
    // this.props.setToggleSlider(true);
    // this.player.pause();
    if (this.player) {
      this.player.seek(seconds);
    }
    // this.player.play();
    // this.props.setToggleSlider(false);
  };
  getJsonData(data) {}
  updateParent(nextState) {
    this.setState(nextState);
  }
  render() {
    return (
      <>
        <div
          style={{
            // marginInline: "20px",
            display: "flex",
            flexDirection: "column",
            "justify-content": "center",
            "align-items": "center",
          }}
        >
          {/* Video+RythmoBand processing canvas */}
          <canvas id="myVid" ref={this.vidCanva} hidden>
            Your browser Doesn't support this Feature
          </canvas>
          <Box
            sx={{
              // height: "60%",
              width: "100%",
              // position: "relative",
              // overflow: "hidden",
              height: "50vh",
              margin: "20px",
              paddingInline: "20px",

              "& video": {
                // display: "none",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              },
            }}
          >
            <Player
              fluid={false}
              width={"100%"}
              height={"100%"}
              src={this.props.source && this.props.source}
              startTime={convertToSecInt(this.props.initialTime)}
              ref={(player) => {
                this.player = player;
              }}
            >
              <source src={this.props.source && this.props.source} />

              <video
                ref={this.vidd}
                style={{ display: "none" }}
                src={this.props.source && this.props.source}
                onLoadedMetadata={this.handleLoadedMetadata}
              />
              <ControlBar autoHide={false} />
            </Player>
            {/* <h1>{window.innerWidth}</h1> */}
          </Box>
          {/* <h1>{this.props.name}</h1> */}
          <Editor
            //methods
            changeCurrentTime={this.changeCurrentTime}
            changeVolume={this.changeVolume}
            setMuted={this.setMuted}
            save={this.save}
            play={this.play}
            pause={this.pause}
            //Refs
            recorder={this.recorder.current}
            vidd={this.vidd.current}
            vidProg={this.vidProg}
            //State
            paused={this.state.paused}
            generating={this.state.generating}
            pauseRender={this.state.pauseRender}
            //setState funcs
            updateParent={this.updateParent}
            //Rythmoband Props
            player={this.state.player}
            plyr={this.player}
            dialogueNumber={this.props.dialogueNumber}
            rythmoPosition={this.props.rythmoPosition}
            timer={this.state.timer}
            //AudioPlayer Props
            muteVideo={this.muteVideo}
            getCurrentTime={this.getCurrentTime()}
            voiceList={this.props.voiceList}
            videoCurrentTime={this.state.player.currentTime}
            videoMuteState={this.state.player.muted}
            videoPlay={this.play}
            videoPause={this.pause}
            vidHot={this.props.vidHot}
            getJsonData={(x) => {
              this.jsonArray = x;
            }}
            //TimeSlider Props

            onSeekbarChange={this.onSeekbarChange}
            seeker={this.seeker}
            changePlaybackRateRate={this.changePlaybackRateRate}
            jsonArray={this.jsonArray}
          />
          {/* ******************************************************** */}
          {/* <Box
      sx={{
        backgroundColor: "white",
        padding: "16px",
        borderTop: "1px solid #707070",
        maxWidth: "100vw",
        overflow: "hidden",
        minWidth: { xs: "100vw", sm: "auto" },
        //   width: { sm: `calc(100% - ${drawerWidth}px)` }
        width: { xs: "100%" },
      }}
    >
      <div className="py-3" style={{ marginTop: "10px" }}>
        {/* <Button
        onClick={this.load}
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px" }}
      >
        load
      </Button> */}
          {/* <Box>
          <IconButton
            onClick={this.changeCurrentTime(-10)}
            variant="contained"
            component="label"
            color="primary"
            style={{ marginLeft: "10px" }}
          >
            <img src={seekback} alt="" />
          </IconButton>

          {this.state.paused ? (
            <IconButton
              onClick={this.play}
              sx={
                {
                  // display: this.state.paused ? "none" : "inline",
                }
              }
              variant="contained"
              component="label"
              color="primary"
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
            >
              <img src={play} alt="" />
            </IconButton>
          ) : (
            <IconButton
              onClick={this.pause}
              variant="contained"
              component="label"
              color="primary"
              sx={
                {
                  // display: this.state.paused ? "inline" : "none",
                }
              }
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
            >
          
              <Pause sx={{ fontSize: "48px", color: "#1955AA" }} />
      
            </IconButton>
          )}
          <IconButton
            onClick={this.changeCurrentTime(10)}
            variant="contained"
            component="label"
            color="primary"
            style={{ marginLeft: "10px" }}
          >
            <img src={seekfront} alt="" />
          </IconButton>
        </Box> */}
          {/* <Button
        onClick={this.seek(50)}
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px" }}
      >
        currentTime = 50
      </Button> */}
          {/* <div>
          <IconButton
            onClick={this.changeVolume(-0.1)}
            variant="contained"
            component="label"
            color="primary"
            style={{ marginLeft: "10px" }}
            sx={{ fontSize: "40px", color: "#1955AA" }}
          >
   
            <VolumeDownIcon />
          </IconButton>
          <img src={Line} alt="" />
          <IconButton
            onClick={this.changeVolume(0.1)}
            variant="contained"
            component="label"
            color="primary"
            style={{ marginLeft: "10px" }}
            sx={{ fontSize: "40px", color: "#1955AA" }}
          >

            <VolumeUpIcon />
          </IconButton>
        </div> */}
          {/* <div
          style={{
            display: "flex",
            // flexDirection: "row",
            alignItems: "center",
            // marginLeft: "85%",
          }}
        >
          <IconButton
            onClick={() => {
              document.getElementById("mainContainer").style.zoom = `${
                this.state.zoomPercent + 50
              }%`;
              // setzoomPercent(zoomPercent + 50);
              this.setState({
                ...this.state,
                zoomPercent: this.state.zoomPercent + 50,
              });
            }}
            variant="contained"
            component="label"
            color="primary"
            // style={{ marginLeft: "10px", height: "30px" }}
          >
            <img src={zoomIn} alt="zoom in" />
          </IconButton>
          <img src={Line} alt="" />
          <IconButton
            onClick={() => {
              document.getElementById("mainContainer").style.zoom = `${
                this.state.zoomPercent - 50
              }%`;
              // setzoomPercent(zoomPercent + 50);
              this.setState({
                ...this.state,
                zoomPercent: this.state.zoomPercent - 50,
              });
            }}
            variant="contained"
            component="label"
            color="primary"
            // style={{ marginLeft: "10px", height: "30px" }}
          >
            <img src={zoomOut} alt="zoom out" />
          </IconButton>
        </div> */}
          {/* <Button
          onClick={this.setMuted(true)}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px" }}
        >
          muted=true
        </Button>
        <Button
          onClick={this.setMuted(false)}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px" }}
        >
          muted=false
        </Button>
        <Button
          onClick={this.save}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px" }}
          onKeyUp={(e) => {
            if (e.code === "Space") {
              e.preventDefault();
            }
          }}
        >
          save project
        </Button>
        <Button
          onClick={async () => {
            await this.recorder.current.resumeRecording();
            this.vidd.current.play();

            this.setState({
              ...this.state,
              generating: true,
              pauseRender: false,
            });
          }}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px" }}
          onKeyUp={(e) => {
            if (e.code === "Space") {
              e.preventDefault();
            }
          }}
        >
          {
            this.state.generating ? (
              <>
                {}
                {this.state.pauseRender ? (
                  "Continue Rendering"
                ) : (
                  <>
                    Video Progress: <span ref={this.vidProg}>loading...</span>
                  </>
                )}
              </>
            ) : (
              <>Generate Video</>
            )

            // {!this.state.generating && "Generate Video"}
          }
        </Button>
        {this.state.generating && (
          <>
            <Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  generating: false,
                });

                this.vidd.current.pause();
                // console.log("The ID", this.state.vidId);

                // clearInterval(this.state.vidId);
                // this.setState({ vidId: null });
                // this.vidId.current = null;
              }}
              variant="contained"
              component="label"
              color="primary"
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
              disabled={this.state.pauseRender}
            >
              Stop rendering
            </Button>

            <Button
              onClick={() => {
                this.vidd.current.pause();

                console.log("The ID", this.state.vidId);
                this.setState({ ...this.state, pauseRender: true });
                // clearInterval(this.state.vidId);
                // this.setState({ vidId: null });
                // this.vidId.current = null;
              }}
              variant="contained"
              component="label"
              color="primary"
              style={{ marginLeft: "10px" }}
              onKeyUp={(e) => {
                if (e.code === "Space") {
                  e.preventDefault();
                }
              }}
            >
              Pause rendering
            </Button>
          </>
        )}
      </div>
      <Rythmoband
        player={this.state.player}
        dialogueNumber={this.props.dialogueNumber}
        rythmoPosition={this.props.rythmoPosition}
        timer={this.state.timer}
      />
      <AudioPlayer
        muteVideo={this.muteVideo}
        getCurrentTime={this.getCurrentTime()}
        voiceList={this.props.voiceList}
        videoCurrentTime={this.state.player.currentTime}
        videoMuteState={this.state.player.muted}
        videoPlay={this.play}
        videoPause={this.pause}
        vidHot={this.props.vidHot}
        player={this.state.player}
        generating={!this.state.pauseRender}
        getJsonData={(x) => {
          this.jsonArray = x;
        }}
      />
      <TimeSlider
        player={this.state.player}
        onSeekbarChange={this.onSeekbarChange}
        seeker={this.seeker}
        play={this.play}
        pause={this.pause}
        changePlaybackRateRate={this.changePlaybackRateRate}
        jsonArray={this.jsonArray}
        vidHot={this.props.vidHot}
        generating={!this.state.pauseRender}
      /> */}
          {/* </Box> */}
        </div>
        {/* <div id="container"></div> */}
      </>
    );
  }
}

// PlayerControlExample.contextType = mainContext.Consumer;

export default VideoPlayer;
