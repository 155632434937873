import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Slider from "@mui/material/Slider";
import Waveclip from "./waveform/Waveclip";
import Box from "@mui/material/Box";
import moment from "moment";
import "./TimeSlider.css";
import slider from "../images/thumb.png";
import Button from "@mui/material/Button";
// import { mainContext } from "../App";
// mainContext
import Buffer from "Buffer";
import { ColorLensOutlined, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { mainContext } from "../services/context";
import audioBufferToWav from "audiobuffer-to-wav";
import Rythmoband from "./rythmoband/rythmoband";
import { IconButton } from "@mui/material";

const audioContext = new AudioContext();

function TimeSlider(props) {
  const {
    player,
    onSeekbarChange,
    seeker,
    play,
    pause,
    changePlaybackRateRate,
    jsonArray,
    generating,
  } = props;
  const {
    recordingData,
    setRecordingData,
    jsonData,
    setJsonData,
    recordings,
    setRecordings,
    toggleSlider,
    setToggleSlider,
    statusArray,
    setStatusArray,
    redoArray,
    setRedoArray,
    channels,
  } = useContext(mainContext);
  const [marks, setMarks] = useState([]);
  const [sliderValue, setSliderValue] = useState(player.currentTime);
  const [sliderWidth, setSliderWidth] = useState(1400);

  const [cMargin, setCMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [segments, setSegments] = useState([]);
  const [audio, setAudio] = useState("");
  const myref = useRef(null);
  const segRef = useRef(null);
  const audioRef = useRef();
  const prevRef = useRef(null);
  const currentRef = useRef(null);
  const editorRef = useRef(null);
  useEffect(() => {
    prevRef.current = 0;
    currentRef.current = 0;
    // console.log(editorRef.current.style.height);
    window.addEventListener(
      "keydown",
      function (e) {
        if ([39, 37].indexOf(e.keyCode) > -1) {
          e.preventDefault();
        }
      },
      false
    );
  }, []);
  useEffect(() => {
    setSliderValue(player.currentTime);
  }, [player]);
  useEffect(() => {
    // console.log("playerrrrrrrrrr2", player.paused);
    if (player.paused) {
      setToggleSlider(false);
    } else {
      setToggleSlider(true);
    }
  }, [player.paused]);
  useEffect(() => {
    // recordingData.map((data, key) => {
    setSegments(recordingData);
    // });
  }, [recordingData]);
  const splitTime = (startTime, endTime, interval) => {
    const result = [startTime.toString().split(" ")[4]];
    let time = startTime.add(interval, "m");
    while (time.isBetween(startTime, endTime, undefined, [])) {
      result.push(time.toString().split(" ")[4]);
      time = time.add(interval, "m");
    }
    return result;
  };
  useEffect(() => {
    let Sec = 0;
    const intervalBySeconds = player.duration / 30;
    for (var i = 0; i <= intervalBySeconds; i++) {
      Sec = Sec + 30;
      let currentSec = Sec;
      setMarks((marks) => [
        ...marks,
        {
          value: currentSec,
          label: convertHMS(currentSec),
        },
      ]);
    }
  }, [player.duration]);
  useEffect(() => {
    if (toggleSlider) {
      const Segment = segments.filter(
        (segment) =>
          segment.from < player.currentTime && segment.to > player.currentTime
      );
      currentRef.current = Segment[0]?.from;
      // console.log("working1", currentRef?.current, prevRef?.current);
      if (Segment.length > 0 && currentRef?.current != prevRef?.current) {
        // it will work if we found a new video
        // if audio time match and current audio is not = prev audio
        // console.log("working2");
        if (audioRef.current !== null) {
          audioRef?.current?.pause();
        }
        const breakValue = player.currentTime - Segment[0]?.from;
        audioRef.current = new Audio(Segment[0].sourceAudio);
        audioRef.current.play();
        audioRef.current.currentTime = breakValue;
        setAudio(Segment[0].sourceAudio);
        prevRef.current = Segment[0]?.from;
      } else if (Segment.length < 1 && prevRef?.current !== 0) {
        // if current audio is working && we switch to empty path
        // console.log("working else if");
        audioRef?.current?.pause();
        prevRef.current = 0;
      } else {
        // console.log("working else");
      }
    } else {
      // if slider is not moving
      if (audioRef.current) {
        audioRef.current.pause();
      }
    }
  }, [player.currentTime]);

  //Shortcuts for TimeSlider

  useEffect(() => {
    props.seeker(-recordingData[0]?.recordingDuration);
    // console.log(-recordingData[0]?.durtion);
  }, [props.vidHot.G]);

  useEffect(() => {
    props.seeker(5);
  }, [props.vidHot.f]);
  useEffect(() => {
    props.seeker(-5);
  }, [props.vidHot.b]);
  useEffect(() => {
    props.seeker(10);
  }, [props.vidHot.ff]);
  useEffect(() => {
    props.seeker(-10);
  }, [props.vidHot.bb]);
  useEffect(() => {
    handleUndo();
  }, [props.vidHot.undo]);
  useEffect(() => {
    handleRedo();
  }, [props.vidHot.redo]);

  function convertHMS(value) {
    const sec = parseInt(value); // convert value to number if it's a string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; // get seconds

    let timeString = "";

    if (hours > 0) {
      // add leading zero if hours < 10; Example: 2 => 02
      timeString += hours < 10 ? "0" + hours : hours;
      timeString += ":";
    }

    // add leading zero if minutes < 10; Example: 2 => 02
    timeString += minutes < 10 ? "0" + minutes : minutes;
    timeString += ":";

    // add leading zero if seconds < 10; Example: 2 => 02
    timeString += seconds < 10 ? "0" + seconds : seconds;

    return timeString; // Return is HH:MM:SS or MM:SS
  }
  function random(number) {
    return Math.floor(Math.random() * number);
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  //Trim audio func
  function trimAudio(audioBuffer, start, end) {
    const sampleRate = audioBuffer.sampleRate;
    const length = audioBuffer.length;
    const channels = audioBuffer.numberOfChannels;

    const startSamples = start * sampleRate;
    const endSamples = end * sampleRate;

    if (
      startSamples >= endSamples ||
      startSamples >= length ||
      endSamples <= 0
    ) {
      return null; // Invalid trim range
    }

    const trimStart = Math.max(0, startSamples);
    const trimEnd = Math.min(length, endSamples);

    const duration = trimEnd - trimStart;

    const trimmedContext = new OfflineAudioContext(
      channels,
      duration,
      sampleRate
    );
    const trimmedBuffer = trimmedContext.createBuffer(
      channels,
      duration,
      sampleRate
    );

    for (let channel = 0; channel < channels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      const trimmedChannelData = trimmedBuffer.getChannelData(channel);
      trimmedChannelData.set(channelData.subarray(trimStart, trimEnd));
    }

    const source = trimmedContext.createBufferSource();
    source.buffer = trimmedBuffer;
    source.connect(trimmedContext.destination);
    source.start();

    return trimmedContext.startRendering();
  }
  async function removeFirst_N_SecondsFromAudio(
    seconds,
    arrayBuffer,
    totalSeconds
  ) {
    const originalArrayBuffer = arrayBuffer; // your original ArrayBuffer
    const bytesPerSecond = 4800 * channels; // sample rate * channels * bytes per sample
    const bytesToRemove = seconds * bytesPerSecond;
    //copy array buffer to prevent disconnection
    const copy = originalArrayBuffer.slice(0);
    const dat = await audioContext?.decodeAudioData(copy);
    // console.log("MY", dat);
    // console.log(seconds);
    const newAud = await trimAudio(
      dat,
      seconds.toFixed(1),
      totalSeconds.toFixed(1)
    );

    // console.log("YOUR", newAud);
    // const bytesToRemove = seconds * bytesPerSecond;
    // console.log("Original Array...", originalArrayBuffer);
    // const totalBytes = originalArrayBuffer.byteLength;
    const renderedBuffer2 = audioBufferToWav(newAud);
    const audioBlob = new Blob([renderedBuffer2], {
      type: "audio/wav",
    });

    //
    const totalBytes = originalArrayBuffer.byteLength;
    const newArrayBuffer = originalArrayBuffer.slice(bytesToRemove);

    // const newBlob = new Blob([ss], { type: "audio/mpeg" });
    const updated = await blobToBase64(audioBlob);
    return { newArrayBuffer, updated };
  }

  async function removeLast_N_SecondsFromAudio(seconds, arrayBuffer) {
    const originalArrayBuffer = arrayBuffer; // your original ArrayBuffer
    // const secondsToRemove = seconds; // number of seconds to remove
    const bytesPerSecond = 4800 * channels; // sample rate * channels * bytes per sample
    const copy = originalArrayBuffer.slice(0);
    const dat = await audioContext?.decodeAudioData(copy);
    // console.log("MY", dat);
    const newAud = await trimAudio(dat, 0, seconds.toFixed(1));
    // const arr = new Uint8Array(arrayBuffer);
    // console.log("YOUR", newAud);
    const bytesToRemove = seconds * bytesPerSecond;
    // console.log("Original Array...", originalArrayBuffer);
    const totalBytes = originalArrayBuffer.byteLength;
    const renderedBuffer2 = audioBufferToWav(newAud);
    const audioBlob = new Blob([renderedBuffer2], {
      type: "audio/wav",
    });

    const newArrayBuffer = originalArrayBuffer.slice(
      0,
      totalBytes - bytesToRemove
    );

    // const newBlob = new Blob([newArrayBuffer], { type: "audio/wav" });
    const lastRemoved = await blobToBase64(audioBlob);
    // console.log("lastRemoved", lastRemoved);
    return { newArrayBuffer, lastRemoved };
  }
  // console.log("statusArray....", statusArray);
  async function divideAudio(
    breakPoint,
    totalSeconds,
    arrayBuffer,
    originalObj
  ) {
    const removeSeconds = totalSeconds - breakPoint;
    // console.log("arraybuffer", arrayBuffer.buffer);
    const firstPart = await removeLast_N_SecondsFromAudio(
      removeSeconds,
      arrayBuffer.buffer ?? arrayBuffer[0]
    );
    const secondPart = await removeFirst_N_SecondsFromAudio(
      breakPoint,
      arrayBuffer.buffer ?? arrayBuffer[0],
      totalSeconds
    );

    return {
      firstPart,
      secondPart,
    };
  }

  const handleTrim = async () => {
    //Select trimmed segment
    const trimSegment = segments.filter(
      (segment) =>
        segment?.from < player.currentTime && segment?.to > player.currentTime
    );
    // console.log("Trimming", trimSegment[0]);

    //remaining portion
    const breakValue = player.currentTime - trimSegment[0]?.from;
    //Divide the audio
    const dividedAudio = await divideAudio(
      breakValue,
      trimSegment[0].recordingDuration,
      trimSegment[0].buffer,
      trimSegment
    );
    // console.log("divide...", dividedAudio);

    const pullTrimSegment = segments.splice(
      segments.findIndex(
        (segment) =>
          segment.from < player.currentTime && segment.to > player.currentTime
      ),
      1
    );
    //Set up randomized color
    const random1 = random(23);

    const color1 =
      "rgb(" + random(255) + "," + random(255) + "," + random(255) + ")";
    const color2 =
      "rgb(" + random(255) + "," + random(255) + "," + random(255) + ")";

    // status for undo/redo
    setStatusArray([
      ...statusArray,
      {
        status: "trimmed",
        originalObj: trimSegment[0],
        trimmedArray: [
          {
            color: color1,
            width:
              (player.currentTime / player.duration) * 100 -
              (trimSegment[0].from / player.duration) * 100,
            audioNumber: random1,
            startTime: trimSegment[0].from,
            endTime: trimSegment[0].from + breakValue,
            widthFrom: (trimSegment[0].from / player.duration) * 100,
            widthTo: (player.currentTime / player.duration) * 100,
            durtion: player.duration,
            from: trimSegment[0].from,
            to: player.currentTime,
            buffer: dividedAudio.firstPart.newArrayBuffer,
            sourceAudio: dividedAudio.firstPart.lastRemoved,
          },
          {
            color: color2,
            width:
              (trimSegment[0].to / player.duration) * 100 -
              (player.currentTime / player.duration) * 100,
            audioNumber: random1 + 1,
            startTime: trimSegment[0].from + breakValue,
            endTime: trimSegment[0].from,
            widthFrom: (player.currentTime / player.duration) * 100,
            widthTo: (trimSegment[0].to / player.duration) * 100,
            durtion: player.duration,
            from: player.currentTime,
            to: trimSegment[0].from,
            buffer: dividedAudio.secondPart.newArrayBuffer,
            sourceAudio: dividedAudio.secondPart.updated,
          },
        ],
      },
    ]);
    // alter recorded data state
    setRecordingData([
      ...recordingData,
      {
        color: color1,
        width:
          (player.currentTime / player.duration) * 100 -
          (trimSegment[0].from / player.duration) * 100,
        audioNumber: random1,
        startTime: trimSegment[0].from,
        endTime: trimSegment[0].from + breakValue,
        widthFrom: (trimSegment[0].from / player.duration) * 100,
        widthTo: (player.currentTime / player.duration) * 100,
        durtion: player.duration,
        from: trimSegment[0].from,
        to: player.currentTime,
        buffer: dividedAudio.firstPart.newArrayBuffer,
        sourceAudio: dividedAudio.firstPart.lastRemoved,
        //fields for faulty Fix of blank trim
        originalObj: trimSegment[0],
        trimmed: true,
        firstPart: true,
      },
      {
        color: color2,
        width:
          (trimSegment[0].to / player.duration) * 100 -
          (player.currentTime / player.duration) * 100,
        audioNumber: random1 + 1,
        startTime: trimSegment[0].from + breakValue,
        endTime: trimSegment[0].from,
        widthFrom: (player.currentTime / player.duration) * 100,
        widthTo: (trimSegment[0].to / player.duration) * 100,
        durtion: player.duration,
        from: player.currentTime,
        to: trimSegment[0].from,
        buffer: dividedAudio.secondPart.newArrayBuffer,
        sourceAudio: dividedAudio.secondPart.updated,
        //fields for faulty Fix of blank trim
        originalObj: trimSegment[0],
        trimmed: true,
        secondPart: true,
        // sourceAudio: dividedAudio.firstPart.lastRemoved,
      },
    ]);
  };

  const dragFunction = (e, i) => {};
  function _base64ToArrayBuffer(x) {
    const binaryString = atob(x.splice(1, -1));
    const arrayBuffer = new Uint8Array(binaryString.length).map((_, i) =>
      binaryString.charCodeAt(i)
    );
  }
  const dragEndFunction = (e, i) => {
    const moveBy = e.clientX - 130;
    const test = recordingData.map((obj, index) => {
      if (obj.color === recordingData[i].color) {
        setStatusArray([
          ...statusArray,
          {
            status: "dragged",
            originalObj: {
              ...segments[i],
            },
          },
        ]);
        return {
          audioNumber: segments[i].audioNumber,
          color: segments[i].color,
          width: segments[i].width,
          widthFrom:
            ((moveBy - segRef.current.offsetLeft) * 100) /
            segRef.current.offsetWidth,
          widthTo:
            ((moveBy - segRef.current.offsetLeft) * 100) /
              segRef.current.offsetWidth +
            (segments[i].widthTo - segments[i].widthFrom),
          durtion: player.duration,
          from:
            ((((moveBy - segRef.current.offsetLeft) * 100) /
              segRef.current.offsetWidth) *
              player.duration) /
            100,
          to:
            ((((moveBy - segRef.current.offsetLeft) * 100) /
              segRef.current.offsetWidth +
              (segments[i].widthTo - segments[i].widthFrom)) *
              player.duration) /
            100,
          startTime:
            ((((moveBy - segRef.current.offsetLeft) * 100) /
              segRef.current.offsetWidth) *
              player.duration) /
            100,
          endTime: segments[i].endTime,
          buffer: segments[i].buffer,
          bufferAddress: segments[i].buffer[0],
          sourceAudio: segments[i].sourceAudio,
          url: segments[i].sourceAudio,
          base64: segments[i].sourceAudio,
        };
      }
      return obj;
    });
    setRecordingData(test);
  };
  // console.log("Status Array.....", statusArray);
  const [newArray, setNewArray] = useState([]);
  var remainingStatus = [];
  // --------------------------handle undo-----------------------------------
  const handleUndo = () => {
    if (recordingData.length > 0 && statusArray.length > 0) {
      const statusObj = statusArray[statusArray.length - 1];
      if (statusObj.status === "created") {
        // console.log("Last status is created", statusObj);
        setRedoArray([...redoArray, statusObj]);
        remainingStatus = statusArray.slice(0, statusArray.length - 1);
        setStatusArray([...remainingStatus]);
        // Now we will remove audio from original array

        const remainingArray = recordingData.filter(
          (_) => _.audioNumber != statusObj.originalObj.audioNumber
        );
        setRecordingData([...remainingArray]);
      }
      if (statusObj.status === "trimmed") {
        // console.log("Last status is trimmed", statusObj);
        setRedoArray([...redoArray, statusArray[statusArray.length - 1]]);
        remainingStatus = statusArray.slice(0, statusArray.length - 1);
        setStatusArray([...remainingStatus]);
        const remainingArray = recordingData.filter(
          (_) =>
            !statusObj.trimmedArray
              .map((obj) => obj.audioNumber)
              .includes(_.audioNumber)
        );
        setRecordingData([...remainingArray, { ...statusObj.originalObj }]);
      }
      if (statusObj.status === "dragged") {
        // console.log("Last Status is Dragged", statusObj);
        const tempArray = recordingData.filter(
          (_) => _.audioNumber == statusObj.originalObj.audioNumber
        );
        // console.log("TempArray....", tempArray);
        setRedoArray([
          ...redoArray,
          { status: "dragged", originalObj: tempArray[0] },
        ]);
        remainingStatus = statusArray.slice(0, statusArray.length - 1);
        // console.log("Status Array1234", remainingStatus);
        setStatusArray([...remainingStatus]);
        const remainingArray = recordingData.filter(
          (_) => _.audioNumber != statusObj.originalObj.audioNumber
        );
        // console.log("remaining Array...", remainingArray);
        setRecordingData([...remainingArray, { ...statusObj.originalObj }]);
      }
    }
  };
  const handleRedo = () => {
    if (redoArray.length > 0) {
      const redoObj = redoArray[redoArray.length - 1];
      // console.log("RedoObj...", redoObj);
      if (redoObj.status === "created") {
        // console.log("I am created");
        const redoRemainingArray = redoArray.slice(0, redoArray.length - 1);
        setRedoArray([...redoRemainingArray]);
        setRecordingData([...recordingData, redoObj.originalObj]);
        setStatusArray([...statusArray, redoObj]);
      }
      if (redoObj.status === "trimmed") {
        const redoRemainingArray = redoArray.slice(0, redoArray.length - 1);
        setRedoArray([...redoRemainingArray]);
        const remainingArray = recordingData.filter(
          (_) => _.audioNumber !== redoObj.originalObj.audioNumber
        );
        setRecordingData([...remainingArray, ...redoObj.trimmedArray]);
        setStatusArray([...statusArray, redoObj]);
      }
      if (redoObj.status === "dragged") {
        // console.log("I am indside Dragged");
        const redoRemainingArray = redoArray.slice(0, redoArray.length - 1);
        setRedoArray([...redoRemainingArray]);
        const tempArray = recordingData.filter(
          (_) => _.audioNumber === redoObj.originalObj.audioNumber
        );
        setStatusArray([
          ...statusArray,
          { status: "dragged", originalObj: tempArray[0] },
        ]);
        const remainingArray = recordingData.filter(
          (_) => _.audioNumber !== redoObj.originalObj.audioNumber
        );
        setRecordingData([...remainingArray, redoObj.originalObj]);
        // setStatusArray([...statusArray, redoObj]);
      }
    }
  };
  return (
    // <div>
    <>
      {/* <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={() => handleTrim()}
        disabled={generating}
      >
        Trim
      </Button>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={() => handleUndo()}
        disabled={generating}
      >
        Undo
      </Button>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginLeft: "10px", marginTop: "10px" }}
        onClick={() => handleRedo()}
        disabled={generating}
      >
        Redo
      </Button> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "16px",
        }}
      >
        <IconButton
          onClick={() => {
            setSliderWidth(sliderWidth + 200);
          }}
          variant="contained"
          component="label"
          color="primary"
          style={{ height: "30px" }}
          disabled={generating}
        >
          <UnfoldMore sx={{ transform: "rotate(90deg)" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            setSliderWidth(sliderWidth - 200);
          }}
          variant="contained"
          component="label"
          color="primary"
          style={{ marginLeft: "10px", height: "30px" }}
          disabled={generating}
        >
          <UnfoldLess sx={{ transform: "rotate(90deg)" }} />
        </IconButton>
      </div>
      <div
        ref={editorRef}
        style={{
          display: "flex",
          justifyContent: "left",
          marginTop: "20px",
          height: "fit-content",

          maxWidth: 1500,
          overflow: "hidden",
        
 
          paddingTop: "50px",
     paddingBottom:"50px"
        }}
      >
        <Box
          sx={{
            width: sliderWidth,
            position: "relative",
            "& .MuiSlider-thumb": {
              // display: "none",
              borderRadius: "10px",
              backgroundColor: "#1955AA !important",
              // height: `${
              //   editorRef.current?.getBoundingClientRect().height - 50
              // }px !important`,
              height: "350px !important",
              width: "1.5px !important",
              padding: "0px !important",
              top: "175px !important",
              "&::before": {
                position: "absolute",
                top: "0px",
                transform: "translate(-45%,-100%)",
                content: '""',
                // position: "absolute",
                bottom: "0",
                left: " 0",
                width: "0px",
                height: "0px",

                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: " 10px solid #1955AA",
                boxShadow: "none",
                // olor: "#1955AA",
              },
            },
            "& .MuiSlider-track, & .MuiSlider-rail": {
              display: "none", // Hide the track and rail
            },

            "& span .MuiSlider-mark": {
              // display: "none",
              // height: "10px !important",
              // maxHeight: "4px !important",
              padding: "1px !important",
              paddingInline: "0.5px !important",
              top: "4px",
              backgroundColor: "gray !important",

              width: "0px !important",
              // height: "0px !important",
            },
            "& span .MuiSlider-markActive": {
              // display: "none",
              // height: "10px !important",
              // maxHeight: "4px !important",
              padding: "4px !important",
              paddingInline: "0.5px !important",
              top: "4px",
              backgroundColor: "#333 !important",

              // width: "1px !important",
            },
            "& span .MuiSlider-markLabel": {
              // display: "none",
              // height: "10px !important",
              // maxHeight: "4px !important",
              // marginBottom: "6px !important",
              // paddingInline: "1px !important",
              top: "-24px !important",
              fontWeight: "400",
              // width: "1px !important",
              // backgroundColor: "gray !important",
            },
          }}
        >
          <Slider
            sx={{
              zIndex: "9",
              marginBottom: "0px",
              position: "absolute",
              left: "0px",
              top: "-10px",
            }}
            onChange={(e) => {
              // setToggleSlider(false);
              onSeekbarChange(e.target.value);
              setSliderValue(e.target.value);
              // setToggleSlider(true);
            }}
            aria-label="Default"
            slots={
              {
                // thumb: {
                //   // height: "100px",
                //   display: "none",
                //   backgroundImage: `url(${slider})`,
                //   width: 40,
                //   backgroundColor: "green",
                //   marginTop: -20,
                //   marginLeft: -20,
                // },
                // marks: {
                //   height: "4px",
                //   bottom:""
                // },
                // marksActive: {
                //   height: "2px",
                // },
              }
            }
            value={sliderValue}
            valueLabelDisplay="auto"
            getAriaValueText={convertHMS}
            valueLabelFormat={convertHMS}
            marks={marks}
            min={0}
            max={player.duration}
          />
          <Box
            sx={{
              backgroundColor: "#F3F3F3",
              overflow: "hidden",
              paddingTop: "50px",
            }}
          >
            <Rythmoband
              player={props.player}
              dialogueNumber={props.dialogueNumber}
              rythmoPosition={props.rythmoPosition}
              timer={props.timer}
            />
            <div
              ref={segRef}
              style={{
                width: sliderWidth,
                height: "fit-content",
                display: "flex",
                // overflow: "hidden",
                // backgroundColor: "#FF8232",
                // marginTop: "-38px",
                // marginBottom: "100px",
                // paddingTop: "15px",
                alignItems: "top",
                paddingBottom: "10px",
                paddingTop: "10px",

                minHeight: "150px",
                borderRadius: "10px",
                backgroundColor: "#6B6A68",
                border: "2px solid rgb(61, 60, 60, 70%)",
              }}
            >
              {recordingData.map((val, key, arr) => {
                return (
                  <>
                    {/* <div
                    ref={myref}
                    draggable={true}
                    onDrag={(e) => dragFunction(e, key)}
                    onDragEnd={(e) => dragEndFunction(e, key)}
                    onClick={() => {
                      _base64ToArrayBuffer(jsonArray[0]?.base64);
                    }}
                    style={{
                      cursor: "pointer",
                      width: `${val.width - 0.2}%`,
                      backgroundColor: val.color,
                      height: "50px",
                      marginLeft:
                        key > 0
                          ? val.widthFrom - arr[key - 1].widthTo + "%"
                          : (val.from / player.duration) * 100 + "%",
                    }}
                  ></div> */}

                    <div
                      // key={key}
                      draggable={true}
                      onDrag={(e) => dragFunction(e, key)}
                      onDragEnd={(e) => dragEndFunction(e, key)}
                      onClick={() => {
                        _base64ToArrayBuffer(jsonArray[0]?.base64);
                      }}
                      style={{
                        cursor: "pointer",
                        // minWidth:'fit-'
                        width: `${val.width}%`,
                        // width: "min-content",
                        // maxWidth: "min-content",
                        // height: "50px",
                        backgroundColor: val.color,
                        borderRadius: "5px",
                        height: "50%",
                        // marginTop: "",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        marginLeft:
                          key > 0
                            ? val.widthFrom - arr[key - 1].widthTo + "%"
                            : (val.from / player.duration) * 100 + "%",
                      }}
                    >
                      <Waveclip
                        recordingData={val}
                        styles={
                          {
                            // cursor: "pointer",
                            // width: "100%",
                          }
                        }
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
}
export default TimeSlider;
