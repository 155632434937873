export const navItems = [
  { text: "Pricing", link: "/pricing" },
  { text: "About", link: "/about" },
  { text: "User guide", link: "/user-guide" },
  { text: "Privacy Policy", link: "/privacy-policy" },
  // { text: "Login", link: "/login" },
];

export const footerItems = [
  { text: "FAQ", link: "/faq" },
  // { text: "Pricing", link: "/pricing" },
  { text: "About", link: "/about" },
  { text: "User guide", link: "/user-guide" },

  { text: "Login", link: "/login" },
  { text: "Privacy Policy", link: "/privacy-policy" },
];

export const userItems = [
  { text: "Dashboard", link: "" },
  // { text: "Pricing", link: "/pricing" },
  { text: "Projects Library", link: "projects" },
  { text: "SRT Editor", link: "subplayer" },
  // { text: "Settings", link: "settings" },
];
