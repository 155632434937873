import { Box, IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import { parseDate } from "../../../utils/dummyData";
import fileIcon from "../../../images/fileIcon.png";
import { MoreHorizRounded } from "@mui/icons-material";
import { MenuItem } from "react-contextmenu";
import { useNavigate } from "react-router-dom";
import { DialoguesContext, mainContext } from "../../../services/context";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  clearProject,
  deleteProject,
  getAllProjects,
  loadProject,
} from "../../../store/features/appSlice";
import { notify } from "reapop";
import { useEffect } from "react";
function RecentProject({ projectName, updated_at, id }) {
  const { setRecordingData } = useContext(mainContext);
  const [del, setDeleted] = useState(false);
  const { setCurrentTime } = useContext(DialoguesContext);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(getAllProjects());
  }, [del]);

  const handleDelete = (id, projectName) => {
    // console.log(id);
    // console.log(del);
    dispatch(deleteProject(id))
      .then((data) =>
        // console.log(del)
        dispatch(
          notify({
            message: `Successfully Deleted ${projectName} `,
            onDismiss: () => {
              setDeleted(!del);
            },
          })
        )
      )
      .catch((error) => dispatch(notify({ message: error })));
    dispatch(getAllProjects());
    setAnchorEl(null);
    // window.location.pathname = "/";
  };

  const tableStyles = {
    display: "flex",
    paddingInline: "24px",
    paddingRight: "16px",
    border: "1px solid #F0F0F0",
    borderRadius: "100px",
    justifyContent: "space-between",

    cursor: "pointer",
    "& .firstCell": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& img": {
        width: "30px",
        height: "30px",
      },
    },
    "& .secondCell": {
      display: { xs: "none", md: "block" },
      width: "100px",
      textAlign: "center",
    },
  };
  const handleProjectLoad = (projectName, id) => {
    setRecordingData([]);
    setCurrentTime("");
    dispatch(clearProject());
    if (id) {
      dispatch(loadProject(id));
    }
    navigate(`/app/${projectName}`);
  };
  return (
    <Box sx={{ display: "flex", ...tableStyles }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexBasis: "60%",
          gap: "24px",

          alignItems: "center",
        }}
        onClick={() => handleProjectLoad(projectName, id)}
      >
        <div className="firstCell">
          <img src={fileIcon} alt="" />
          <p>{projectName}</p>
        </div>
        <div className="secondCell">
          <p>{parseDate(updated_at)}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          sx={{ zIndex: "999" }}
          tabIndex={1}
          onClick={handleClick}
        >
          <MoreHorizRounded />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              //   maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem>
            {/* onClick={handleClose} */}
            <p
              style={{ cursor: "pointer", margin: "0px", padding: "8px" }}
              onClick={() => handleDelete(id, projectName)}
            >
              Delete
            </p>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
}

export default RecentProject;
