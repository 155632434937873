import React from "react";
import Section from "../components/Section/section";
import { Box, Button } from "@mui/material";
import { Typography } from "@mui/material";
import Lines from "../images/lines.png";
import faqdude from "../images/faqdude.png";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { faqs } from "../utils/faq";
import pblob1 from "../images/pblob1.png";
import pblob2 from "../images/pblob2.png";
import pblob3 from "../images/pblob3.png";

function Faq() {
  const faqSection = {
    display: "flex",
    "& .pblob1, .pblob2, .pblob3": {
      position: "absolute",
      zIndex: "-1",
    },

    "& .pblob2": {
      bottom: "0px",
      right: "10px",
    },
    "& .pblob3": {
      top: "40%",
      left: "-10%",
    },
    "& .pblob1": {
      bottom: "50%",
      right: "-50px",
    },
    flexDirection: "column",
    gap: { xs: "32px", sm: "40px", md: "48px" },
    minHeight: "90vh",
    ".title": {
      alignSelf: { xs: "center", md: "flex-start" },
      width: { xs: "80%", md: "500px" },
      display: "flex",
      paddingTop: "20px",
      flexDirection: "column",
      flexBasis: "auto",
      //   justifyContent: "center",
      alignItems: { xs: "center", md: "start" },

      "& h1": {
        // display: "none",
        fontSize: "clamp(24px, 5vw, 40px)",
        color: "#1955AA",
        fontWeight: "400",
        margin: "0px",
        // paddingLeft: "16px",
      },
      "& img": {
        width: "50%",
      },
    },
    "& .faq": {
      // display: "",
      width: "400px",
      "& h3": {
        fontSize: "clamp(16px, 5vw, 20px)",
        fontWeight: "700",
        margin: "16px",
        marginLeft: "0px",
      },
      "& p": {
        margin: "0px",
        fontSize: "clamp(16px, 5vw, 20px)",
        lineHeight: "1.4",
      },
    },
  };
  return (
    <Section sx={faqSection}>
      <img src={pblob1} className="pblob1" alt="blob1" />
      <img src={pblob2} className="pblob2" alt="blob2" />
      <img src={pblob3} className="pblob3" alt="blob3" />
      <Box className="title">
        <Typography variant="h1">Frequently Asked Questions</Typography>
        <img src={Lines} alt="" className="lines" />
      </Box>
      <Grid2
        container
        spacing={{ xs: 4 }}
        sx={{
          display: "flex",
          alignSelf: "center",
          justifySelf: "center",
          flexBasis: "100%",
          minHeight: "50vh",
          marginBottom: "32px",
          // justify: "center",
          justifyContent: "center",
        }}
      >
        {faqs.map((faq) => (
          <Grid2
            xs={12}
            sm={6}
            md={4}
            className="faq"
            // sx={{ alignContent: "start", textAlign: "left" }}
          >
            <Typography variant="h3">{faq.title}</Typography>
            <Typography variant="p">{faq.answer}</Typography>
          </Grid2>
        ))}
      </Grid2>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#1955AA",
          color: "white",
          fontWeight: "700",
          flexDirection: { xs: "column", md: "row" },
          textAlign: { xs: "center", md: "left" },
          flexBasis: "auto",
          "& .middle": { flexBasis: "100%" },
          width: "70%",
          alignSelf: "center",
          alignItems: "center",
          padding: "16px",
          borderRadius: "16px",
        }}
      >
        <Box
          sx={{
            "& img": { width: "100%" },
            // width: "150px",
            margin: "16px",
            // marginRight: "16px",
          }}
        >
          <img src={faqdude} alt="person" />
        </Box>
        <div className="middle">
          <p>Still have questions?</p>
          <p>
            Can’t find answers you’re looking for? Please chat to our friendly
            team
          </p>
        </div>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FF9820",
            textTransform: "none",
            fontSize: "16px",
            width: "200px",
            margin: "16px",
            padding: "8px",
            transition: ".2s ease-in-out",
            // height: "fit",
            // alig,
            "&:hover": {
              backgroundColor: "#1955AA",
              // color: "#FF9820",
              // textTransform: "Capitalize",
            },
          }}
        >
          {" "}
          Get in touch
        </Button>
      </Box>
    </Section>
  );
}

export default Faq;
