import "../../../node_modules/video-react/dist/video-react.css";
import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useHistory, useNavigate } from "react-router-dom";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import logo from "../../images/logo.png";
import { useState, createContext, useContext } from "react";

import Buffer from "Buffer";
import { Link } from "react-router-dom";
import { loadFile } from "../../services/srtreader";
import { loadJson } from "../../services/srtreader";
import { DialoguesContext, mainContext } from "../../services/context";
import { useEffect } from "react";
import axios from "axios";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadSrt1,
  uploadSrt2,
  uploadSrt3,
  uploadTime1,
  uploadTime2,
  uploadTime3,
  uploadVideo,
} from "../../store/features/appSlice";
import { notify } from "reapop";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "red",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
});
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function SrtUploader({ className }) {
  const [value, setValue] = React.useState(0);
  const [url, seturl] = useState("");
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [srtFilePath, setsrtFilePath] = useState(null);
  const [jsonFilePath, setjsonFilePath] = useState(null);
  const [videoFilePathUploaded, setVideoFilePathUploaded] = useState(false);
  const [srtFilePathUploaded1, setsrtFilePathUploaded1] = useState(false);
  const [srtFilePathUploaded2, setsrtFilePathUploaded2] = useState(false);
  const [srtFilePathUploaded3, setsrtFilePathUploaded3] = useState(false);
  const [jsonFilePathUploaded, setjsonFilePathUploaded] = useState(false);
  const [videoFileError, setVideoFileError] = useState(false);
  const [srtFileError1, setsrtFileError1] = useState(false);
  const [srtFileError2, setsrtFileError2] = useState(false);
  const [srtFileError3, setsrtFileError3] = useState(false);
  const [jsonFileError, setjsonFileError] = useState(false);
  // const history = useHistory();
  // const getCookieValue = (name) =>
  //   document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";
  // useEffect(() => {

  // }, []);

  const {
    Dialogues1,
    setDialogues1,
    Time1,
    setTime1,
    Dialogues2,
    setDialogues2,
    Time2,
    setTime2,
    Dialogues3,
    setDialogues3,
    Time3,
    setTime3,
    source,
    setsource,
    currentTime,
    setCurrentTime,
    dialogueNumber,
    setDialogueNumber,
    rythmoPosition,
    setrythmoPosition,
    projectName,
    setprojectName,
    voiceList,
    setVoiceList,
  } = useContext(DialoguesContext);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const { setRecordingData } = useContext(mainContext);
  const dispatch = useDispatch();
  const handleVideoUpload = (event) => {
    // console.log(URL.createObjectURL(event.target.files[0]));
    try {
      // setprojectName(event.target.files[0].name);
      // setsource(URL.createObjectURL(event.target.files[0]));

      // forceUpdate();
      setVideoFileError(false);
      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);

        if (video.duration > 90 && user.membership === "Basic") {
          dispatch(
            notify({
              onDismiss: () => {
                window.location.pathname = "/pricing";
              },
              message: `Hey ${user.userName}, to work with videos longer than 1:30mins, consider upgrading your membership to get even more benefits from freshdubs`,
              dismissAfter: "5000",
            })
          );
        }
      };
      video.src = URL.createObjectURL(event.target.files[0]);
      // console.log(video.duration);
      // console.log(video.duration > 90, user.membership);
      dispatch(uploadVideo(URL.createObjectURL(event.target.files[0])));
      setVideoFilePathUploaded(true);
      // navigate("/app/projects");
      // window.location.href = "/app/projects";
    } catch {
      setVideoFileError(true);
    }
  };
  const handlesrt1Upload = async (event) => {
    try {
      let { time, dialogue } = await loadFile(event.target.files[0]);
      setDialogues1(dialogue);
      // console.log(dialogue);
      dispatch(uploadSrt1(dialogue));
      dispatch(uploadTime1(time));
      // setTime1(time);
      // console.log(time);
      setsrtFilePathUploaded1(true);
      setsrtFileError1(false);
    } catch {
      setsrtFileError1(true);
    }
  };
  const handlesrt2Upload = async (event) => {
    try {
      let { time, dialogue } = await loadFile(event.target.files[0]);
      dispatch(uploadSrt2(dialogue));
      dispatch(uploadTime2(time));
      setsrtFilePathUploaded2(true);
      setsrtFileError2(false);
    } catch {
      setsrtFileError2(true);
    }
  };
  const handlesrt3Upload = async (event) => {
    try {
      let { time, dialogue } = await loadFile(event.target.files[0]);
      dispatch(uploadSrt3(dialogue));
      dispatch(uploadTime3(time));
      setsrtFilePathUploaded3(true);
      setsrtFileError3(false);
    } catch {
      setsrtFileError3(true);
    }
  };
  const handlejsonUpload = async (event) => {
    try {
      let savedArray = await loadJson(event.target.files[0]);
      const len = savedArray.length;

      //New structure
      setCurrentTime(savedArray[len - 1].currentTime);
      setRecordingData([...savedArray.slice(0, len - 1)]);

      //Old structure
      // setDialogueNumber(dial_number);
      // setrythmoPosition(rythmo_position);
      // setjsonFilePathUploaded(true);
      setjsonFileError(false);
    } catch {
      setjsonFileError(true);
    }
  };

  function onChange(event) {
    var reader = new FileReader();
    // reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
    setjsonFilePathUploaded(true);
    handlejsonUpload(event);
  }
  var lookup = [];
  function base64ToArrayBuffer(base64) {
    const binaryString = Buffer.from(base64, "base64").toString("binary");
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  var newArray = [];
  async function onReaderLoad(event) {
    var obj = JSON.parse(event.target.result);
    setVoiceList([...obj.splice(0, 2)]);
    await obj.map(async (_, i) => {
      var x;
      const j = await fetch(obj[0].base64)
        .then((b) => b.arrayBuffer())
        .then((buff) => {
          x = new Int8Array(buff);
          // console.log("llll", x);
          newArray.push({ ..._, buffer: x });
          setVoiceList([...newArray]);
          return x;
        })
        .catch((e) => console.log(e));
    });
  }
  console.log("response...", voiceList);
  return (
    <Card className={className}>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginTop: "30px" }}
      >
        {" "}
        {videoFilePathUploaded ? "" : <AddIcon />}
        {videoFilePathUploaded ? "Uploaded" : "Upload Video"}
        <input type="file" onChange={(e) => handleVideoUpload(e)} hidden />
      </Button>
      {videoFileError ? (
        <p style={{ color: "red" }}>There was an error in uploading</p>
      ) : (
        <p></p>
      )}
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginTop: "30px" }}
      >
        {" "}
        {srtFilePathUploaded1 ? "" : <AddIcon />}
        {srtFilePathUploaded1 ? "Uploaded" : "Upload srt 1"}
        <input type="file" onChange={handlesrt1Upload} hidden />
      </Button>

      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginTop: "30px" }}
      >
        {" "}
        {srtFilePathUploaded2 ? "" : <AddIcon />}
        {srtFilePathUploaded2 ? "Uploaded" : "Upload srt 2"}
        <input type="file" onChange={handlesrt2Upload} hidden />
      </Button>
      {srtFileError2 ? (
        <p style={{ color: "red" }}>There was an error in uploading</p>
      ) : (
        <p></p>
      )}
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ marginTop: "30px" }}
      >
        {" "}
        {srtFilePathUploaded3 ? "" : <AddIcon />}
        {srtFilePathUploaded3 ? "Uploaded" : "Upload srt 3"}
        <input type="file" onChange={handlesrt3Upload} hidden />
      </Button>
      {srtFileError3 ? (
        <p style={{ color: "red" }}>There was an error in uploading</p>
      ) : (
        <p></p>
      )}
    </Card>
    // legacySRTUPLOADER
    // <>
    //   <div
    //     style={{
    //       marginTop: "150px",
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "center",
    //       alignItems: "center",
    //     }}
    //   >
    //     <img
    //       src={logo}
    //       alt="this is logo"
    //       style={{ height: "200px", width: "800px" }}
    //     />
    //     <Button
    //       variant="contained"
    //       component="label"
    //       color="primary"
    //       style={{ marginTop: "30px" }}
    //     >
    //       {" "}
    //       {videoFilePathUploaded ? "" : <AddIcon />}
    //       {videoFilePathUploaded ? "Uploaded" : "Upload Video"}
    //       <input type="file" onChange={handleVideoUpload} hidden />
    //     </Button>
    //     {videoFileError ? (
    //       <p style={{ color: "red" }}>There was an error in uploading</p>
    //     ) : (
    //       <p></p>
    //     )}
    //     <Button
    //       variant="contained"
    //       component="label"
    //       color="primary"
    //       style={{ marginTop: "30px" }}
    //     >
    //       {" "}
    //       {srtFilePathUploaded1 ? "" : <AddIcon />}
    //       {srtFilePathUploaded1 ? "Uploaded" : "Upload srt 1"}
    //       <input type="file" onChange={handlesrt1Upload} hidden />
    //     </Button>
    //     {srtFileError1 ? (
    //       <p style={{ color: "red" }}>There was an error in uploading</p>
    //     ) : (
    //       <p></p>
    //     )}
    //     <Button
    //       variant="contained"
    //       component="label"
    //       color="primary"
    //       style={{ marginTop: "30px" }}
    //     >
    //       {" "}
    //       {srtFilePathUploaded2 ? "" : <AddIcon />}
    //       {srtFilePathUploaded2 ? "Uploaded" : "Upload srt 2"}
    //       <input type="file" onChange={handlesrt2Upload} hidden />
    //     </Button>
    //     {srtFileError2 ? (
    //       <p style={{ color: "red" }}>There was an error in uploading</p>
    //     ) : (
    //       <p></p>
    //     )}
    //     <Button
    //       variant="contained"
    //       component="label"
    //       color="primary"
    //       style={{ marginTop: "30px" }}
    //     >
    //       {" "}
    //       {srtFilePathUploaded3 ? "" : <AddIcon />}
    //       {srtFilePathUploaded3 ? "Uploaded" : "Upload srt 3"}
    //       <input type="file" onChange={handlesrt3Upload} hidden />
    //     </Button>
    //     {srtFileError3 ? (
    //       <p style={{ color: "red" }}>There was an error in uploading</p>
    //     ) : (
    //       <p></p>
    //     )}
    //     <Button
    //       variant="contained"
    //       component="label"
    //       color="primary"
    //       style={{ marginTop: "30px" }}
    //     >
    //       {" "}
    //       {jsonFilePathUploaded ? "" : <AddIcon />}
    //       {jsonFilePathUploaded ? "Uploaded" : "Upload json"}
    //       <input type="file" onChange={onChange} hidden />
    //     </Button>
    //     {jsonFileError ? (
    //       <p style={{ color: "red" }}>There was an error in uploading</p>
    //     ) : (
    //       <p></p>
    //     )}
    //     <p>Upload JSON only if previously saved otherwise leave empty</p>

    //     <Link to="project" style={{ "text-decoration": "none" }}>
    //       <Button variant="outlined" style={{ marginTop: "20px" }}>
    //         Submit
    //       </Button>
    //     </Link>
    //   </div>
    // </>
  );
}
