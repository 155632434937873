import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Form, Field, Formik, ErrorMessage } from "formik";
import {
  Card,
  OutlinedInput,
  FormControl,
  FormLabel,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import LOGO from "../../images/freshdubs.png";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
function Reset() {
  const { id, token } = useParams();
  const [data, setData] = useState({ message: "" });
  const [expiryState, setExpiryState] = useState({ message: "" });
  const [expired, setExpired] = useState(false);
  const [sent, setSent] = useState(false);
  const [redirectTimeoutId, setRedirectTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(["resetPass"]);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  useEffect(() => {
    if (getCookie("resetPass") === undefined) {
      // console.log("eurika");
      setExpired(true);
    } else {
      setExpired(false);
    }
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `https://fresh-dubs-server.vercel.app/api/user/reset-password/${id}/${token}`,
          { withCredentials: true }
        );

        setData(response.data);

        // console.log(response.data);
      } catch (error) {
        // console.log("data has expired", error.response.data);
        console.error(error.response.data);
      }
    };
    const handleStorageChange = () => {
      // console.log(getCookie("resetPass"));
      if (getCookie("resetPass") === undefined) {
        // console.log("eurika");
        setExpired(true);
      } else {
        setExpired(false);
      }
    };
    // window.addEventListener("storage", handleStorageChange);
    const expiry = setInterval(() => handleStorageChange(), 5000);

    fetchUser();

    return () => {
      clearInterval(expiry);
      clearTimeout(redirectTimeoutId);
      //   window.removeEventListener("storage", handleStorageChange);
    };
  }, [redirectTimeoutId]);
  const navigate = useNavigate();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `http://localhost:4000/api/user//reset-password/${id}/${token}`,
        values
      );
      //   setUserData(response.data);
      if (response) {
        // console.log(response.data);
        setSent(true);
        setExpiryState("");
        setLoading(false);
      }
      //Change state of page to reflect sent email

      const timeoutId = setTimeout(() => {
        navigate("/login");
      }, 3000);
      setRedirectTimeoutId(timeoutId);
    } catch (error) {
      console.error(error.response.data);
      setSent(false);
      setLoading(false);
      setExpiryState(error.response.data.message);
    }
  };
  const formControlStyles = {
    "& label,.error": {
      textAlign: "left",
      mb: "6px",
      //   alignSelf: "start",
    },
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#1955AA",
      }}
    >
      <Card
        sx={{
          padding: "32px",
          //   width: "fit-content",
          width: { xs: "70%", md: "60%", lg: "800px" },
          "& button": {
            mt: "16px",
          },
        }}
        elevation={10}
      >
        <img src={LOGO} alt="logo" />
        <h1 style={{ textAlign: "start" }}>Reset Password</h1>
        {!expired && (
          <>
            <h3 style={{ textAlign: "start" }}>Hey {data.userName}!</h3>
            <h4>Change your password for {data.email}</h4>
          </>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {!expired ? (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <FormControl sx={{ ...formControlStyles }}>
                <FormLabel>New Password:</FormLabel>
                <Field
                  as={OutlinedInput}
                  name="password"
                  type="password"
                  id="password"
                />
                <ErrorMessage name="password" className="error" />
              </FormControl>
              <FormControl sx={{ ...formControlStyles }}>
                <FormLabel>Confirm New Password:</FormLabel>
                <Field
                  as={OutlinedInput}
                  name="confirmPassword"
                  type="confirmPassword"
                  id="confirmPassword"
                />
              </FormControl>
              <ErrorMessage className="error" name="confirmPassword" />
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ padding: "16px" }}
              >
                Reset Password
              </Button>
            </Form>
          ) : (
            <>
              <h2 style={{ color: "#ff4400 " }}>Token has Expired!</h2>
              <Link to="/login">
                {/* Go back to Login */}
                <Button variant="contained" size="large">
                  Go back to Login{" "}
                </Button>
              </Link>
            </>
          )}
        </Formik>
        {sent && (
          <p style={{ color: "green" }}>
            Recovery link has been sent to your email, expires in{" "}
            <strong style={{ textDecoration: "underline" }}>15mins</strong>
          </p>
        )}
        {expiryState && (
          <p>
            {" "}
            <p style={{ color: "red" }}>{expiryState.message}</p>
          </p>
        )}
      </Card>
    </div>
  );
}

export default Reset;
