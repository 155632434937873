export const faqs = [
  {
    title: "Can I change my plan later?",
    answer:
      "Of course, Our prices scales with our company. Chat to our friendly team to find a solution that works for you.",
  },
  {
    title: "What is your cancellation policy?",
    answer:
      "We understand that things change. You can cancel you plan anytime and we’ll refund you the difference already paid",
  },
  {
    title: "How do I change my account’s email?",
    answer:
      "You can change your email associated with your account by going to your accounts settings.",
  },
];
