import { Box, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { eraseCookie } from "../../utils/cookies";
import { useCookies } from "react-cookie";
import { getUserInfos, logout } from "../../store/features/userSlice";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import dashboardImg from "../../images/rightColumnImg.png";
import helpImg from "../../images/help.png";
import { Link } from "react-router-dom";
import { parseDate, projectList } from "../../utils/dummyData";
import RecentProject from "./components/RecentProject";
import { getAllProjects } from "../../store/features/appSlice";
function User({ online }) {
  const dispatch = useDispatch();
  useEffect(() => {
    // if (online) {
    dispatch(getUserInfos());
    dispatch(getAllProjects());
    // }

    // console.log("The data", user);
  }, [dispatch]);
  const { user } = useSelector((state) => state.user);
  const { projects } = useSelector((state) => state.app);

  const [cookies, setCookie, removeCookie] = useCookies(["myCookie"]);

  return (
    <>
      {/* WELCOME BACK TO YOUR BEAUTIFUL DASHBOARD {user && user.userName}

      <p>
        The {user.membership} user, that you are {":)"}
      </p>
      <h4>Notifications</h4>
      <ul>
        {user.notifications.messages.map((message) => (
          <li>{message}</li>
        ))}
      </ul> */}
      <Grid2 container spacing={3}>
        <Grid2 xs={12} md={8}>
          <Box
            display="flex"
            backgroundColor="rgba(43, 235, 200, 1)"
            alignItems="center"
            borderRadius="8px"
            padding="40px"
            height="100%"
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
              "& p": {
                fontWeight: "700",
                fontSize: "18px",
              },
              "& img": {
                width: "60%",
                minWidth: "200px",
              },
            }}
          >
            <p>Welcome to your dashboard!</p>
            <img src={dashboardImg} alt="welcome" />
          </Box>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Box
            display="flex"
            flexDirection="column"
            backgroundColor="#FFFFFF"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            padding="32px"
            gap="16px"
            height="100%"
            sx={{
              "& a": {
                fontWeight: "700",
                fontSize: "18px",
                textDecoration: "none",
                color: "black",
              },
              "& img": {
                width: "100%",
                minWidth: "150px",
                maxWidth: "240px",
              },
            }}
          >
            <img src={helpImg} alt="help" />
            <Link>Help and Support {"-->"}</Link>
          </Box>
        </Grid2>

        <Grid2 xs={12} height="500px" overflow="scroll">
          <Box backgroundColor="white" padding="20px" borderRadius="8px">
            <h1
              style={{
                paddingInline: "20px",
                fontSize: "20px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              Recent projects
            </h1>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "24px",
                // minHeight: "300px",
                paddingInline: { xs: "24px", md: "24px" },
                "& .date": {
                  display: { xs: "none", md: "block" },
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexBasis: "55%",
                }}
              >
                <h5>Name</h5>
                <h5 style={{ textAlign: "left" }} className="date">
                  Date
                </h5>
              </div>
              <h5>Edit</h5>
            </Stack>
            <Box
              display="flex"
              flexDirection="column"
              // justifyContent="start"
              // minHeight=""
              // maxHeight="330px"
              paddingBottom="24px"
              // overflowY="hidden"
              gap="24px"
            >
              {Array.isArray(projects) && projects?.length > 0 ? (
                [...projects]
                  ?.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  )
                  ?.map((project) => (
                    <RecentProject
                      projectName={project.projectName}
                      updated_at={project.updatedAt}
                      id={project._id}
                    />
                  ))
              ) : (
                <p>No recents here</p>
              )}
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </>
  );
}

export default User;
