import { Card } from "@mui/material";
import React from "react";

function Notifications({ notifications }) {
  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: "16px",
        // padding: "16px",
        width: "270px",
        position: "absolute",
        left: "-600%",
        "& p": {
          borderBottom: "1px solid black",
          padding: "8px",
          cursor: "pointer",
          fontSize: "14px",
          textAlign: "left",
        },
        "& p:last-child": {
          borderBottom: "none",
        },
      }}
    >
      {[...notifications].reverse().map((notification) => (
        <p>{notification}</p>
      ))}
    </Card>
  );
}

export default Notifications;
