import { Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { mainContext } from "../../services/context";
import { useDispatch, useSelector } from "react-redux";

import { Box, Toolbar } from "@mui/material";
import Section from "../../components/Section/section";
import blob1 from "../../images/Union.png";
import blob2 from "../../images/blob2.png";
import heroImg from "../../images/heroImg.svg";
import rythmoBand from "../../images/rythmoband.png";
import second from "../../images/second.png";
import third from "../../images/third.png";
import fourth from "../../images/fourth.png";
import { useNotifications, notify } from "reapop";
function Home() {
  // const { notify } = useNotifications();
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(notify({ allowHTML: true, message: "<h1>FUGG</h1>" }));
  // }, []);

  const [cookies, setCookie, removeCookie] = useCookies(["jwt_Token"]);
  const { userData, setUserData } = useContext(mainContext);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  function eraseCookie(name) {
    document.cookie = name + "=; Max-Age=0";
  }
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const isAuthenticated = getCookie("jwt_Token");
  const handleLogout = () => {
    localStorage.removeItem("jwt");
    setCookie("jwt_Token", "");
    removeCookie("jwt_Token");
    // setUserData({ userName: "" });
  };
  const contentStyles = {
    // display: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    zIndex: "99",
    marginTop: { xs: "113px", sm: "90px" },
    "& .secImg": {
      // display: "none",
      width: "clamp(300px, 50vw, 620px)",
      position: "relative",
      right: { xs: "0", lg: "-100px" },
      // top: { xs: "20px", md: "-150px" },
    },
    "& main": {
      display: "flex",
      position: "relative",
      flexDirection: { xs: "column", md: "row" },
      justifyContent: "space-between",
      paddingLeft: "0px",
      // margin: "0px",
      alignSelf: "left",
      alignItems: "center",
    },
  };
  const HeroStyles = {
    "& .blob1": {
      position: "absolute",
      // display: "none",
      left: "35%",
      top: "-10%",
    },
    "& .heroImg": {
      position: "absolute",
      right: { xs: "-100px", md: "0%" },
      top: { xs: "380px", sm: "410px", md: "200px", lg: "140px" },
      width: { xs: "370px", sm: "400px", md: "450px", lg: "600px" },
    },
    "& .blob2": {
      position: "absolute",
      width: { xs: "200px", md: "300px" },
      bottom: "0%",
      left: { xs: "-100px", md: "-50px", lg: "24px" },
    },
  };
  //   useEffect(() => {});
  return (
    <>
      {/* <Box */}
      <Section sx={HeroStyles}>
        <img src={blob1} className="blob1" alt="blob1" />
        <img src={blob2} className="blob2" alt="blob2" />
        <img src={heroImg} className="heroImg" alt="hero" />
        <Box sx={{ ...contentStyles }}>
          <Box
            component={"article"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "clamp(24px, 8vw, 40px)",
              width: { xs: "400px", lg: "550px" },
            }}
          >
            <Typography variant="h2" sx={{ color: "#1955AA" }}>
              Unleash Your Creativity with Our Editing{" "}
              <span style={{ color: "#FF3C00" }}>Magic</span>!
            </Typography>
            <Typography variant="h3">
              Providing you a powerful set of editing tools and features that
              allow you to bring your vision to life with our editing magic.{" "}
            </Typography>
          </Box>
        </Box>
        <ButtonGroup
          variant="contained"
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "50%",
            width: { xs: "270px", md: "auto" },
            transform: "translate(-50%, 0%)",
            borderRadius: "50px",
            backgroundColor: "#1955AA",
            // padding: "px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "inherit",
              // padding: "14px 22px",
              borderRadius: "inherit",
              padding: { xs: "14px", md: "14px 22px" },
              fontSize: { xs: "12px", md: "16px" },
            }}
            onClick={() => {
              navigate("/app/new");
            }}
          >
            {"Create your video"}
          </Button>
          <Button
            variant="contained"
            sx={{
              //   position: "absolute",
              //   bottom: "0px",
              //   left: "50%",

              //   transform: "translate(-50%, 0%)",
              //   borderRadius: "50px",
              backgroundColor: "inherit",
              padding: { xs: "14px", md: "14px 22px" },
              fontSize: { xs: "12px", md: "16px" },
              borderRadius: "inherit",
            }}
            onClick={() => {
              navigate("/subplayer");
            }}
          >
            {"Edit your SRT"}
          </Button>
        </ButtonGroup>
      </Section>

      <Section>
        <Box
          sx={{
            ...contentStyles,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            gap: "32px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <img
            src={rythmoBand}
            alt="rythmoband"
            style={{ alignSelf: "center" }}
          />

          <Box component={"main"}>
            <Box
              component={"article"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                gap: "clamp(14px, 3vw, 40px)",
                width: { xs: "100%", lg: "550px" },
              }}
            >
              <Typography variant="h2" sx={{ color: "#1955AA" }}>
                All in one <span style={{ color: "#FF3C00" }}>place</span>!
              </Typography>
              <Typography variant="h4">
                We combine three powerful features in one convenient platform:
                Uploading Videos, SRT, and JSON. Now you can edit your videos
                with ease, add subtitles and metadata, all are in one place!
              </Typography>
            </Box>
            <img className="secImg" src={second} alt="" />
          </Box>
        </Box>
      </Section>
      {/* ... */}
      <Section>
        <Box
          sx={{
            ...contentStyles,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            gap: "32px",
          }}
        >
          <Box component={"main"}>
            <img
              className="secImg"
              style={{ right: "0px" }}
              src={third}
              alt=""
            />
            <Box
              component={"article"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                gap: "clamp(14px, 3vw, 40px)",
                width: { xs: "100%", lg: "550px" },
                textAlign: { xs: "center", md: "left" },
                position: "relative",
                right: { xs: "0", lg: "-100px" },
              }}
            >
              <Typography variant="h2" sx={{ color: "#1955AA" }}>
                Edit like a <span style={{ color: "#FF3C00" }}>pro</span>
              </Typography>
              <Typography variant="h4">
                Our online editing software is designed with the user in mind,
                making it easy for anyone to edit videos like a pro. With a
                simple and intuitive interface, powerful editing tools, and
                helpful tutorials, our software makes it easy to create stunning
                videos that capture your unique perspective.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Section>
      {/* ... */}
      <Section>
        <Box
          sx={{
            ...contentStyles,
            // display: "flex",
            // flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            // gap: "32px",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Box component={"main"}>
            <Box
              component={"article"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                gap: "clamp(14px, 3vw, 40px)",
                width: { xs: "100%", lg: "550px" },
              }}
            >
              <Typography variant="h2" sx={{ color: "#1955AA" }}>
                Export your files in{" "}
                <span style={{ color: "#FF3C00" }}>easy formats</span>
              </Typography>
              <Typography variant="h4">
                Edit your audio files with ease and export them in the format
                you need with our editing software. Whether you’re a podcaster,
                musician, or audio engineer, our software has everything you
                need to create the perfect sound. With our intuitive interface,
                you can edit your audio files quickly and easily, and export
                them in mp3 or Wave format. Try our software today and take your
                audio editing to the next level!
              </Typography>
            </Box>
            <img className="secImg" src={fourth} alt="" />
          </Box>
        </Box>
      </Section>
    </>
  );
}

export default Home;
