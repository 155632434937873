import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Logo from "../../images/freshdubs.png";
import face from "../../images/face.png";
import insta from "../../images/insta.png";
import yt from "../../images/yt.png";
import { Button, OutlinedInput, Stack, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { footerItems } from "../../utils/nav";
function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const firstGrid = {
    "& .logo": {
      marginTop: { xs: "67px" },
      width: { xs: "300px" },
    },

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: "1px solid white",
    "& p": {
      //   display: "none",
      fontSize: "20px",
      color: "white",
    },
  };
  const secondGrid = {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" },
    gap: "32px",
    paddingTop: "35px",
    paddingLeft: "35px",
  };
  const thirdGrid = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "32px",
    gap: "16px",
    "& label": {
      // display: "none",
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      //   outline: " none",
      //   display: "none",
      border: "1px solid white",
    },
    "& .MuiOutlinedInput-root:focus": {
      //   outline: " none",
      //   display: "none",
      border: "1px solid white",
    },
    "& .Mui-focused": {
      //   display: "none",
      border: "1px solid white",
    },
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display:
          location.pathname.startsWith("/dashboard") |
          location.pathname.startsWith("/app")
            ? "none"
            : "flex",
        backgroundColor: "#1955AA",
        margin: "0px",
        marginTop: "32px",
        width: "100%",
      }}
    >
      <Grid xs={12} md={4} sx={firstGrid} order={{ xs: 3, md: 1 }}>
        <img src={Logo} className="logo" alt="logo" />
        <p>Unleash Your Creativity with Our Editing Magic! </p>
        <Stack flexDirection={"row"} gap="35px">
          <img src={insta} alt="instagram" />
          <img src={face} alt="facebook" />
          <img src={yt} alt="youtube" />
        </Stack>
        <p style={{ marginTop: "60px" }}>© Copyright 2023</p>
      </Grid>
      <Grid xs={12} md={4} sx={secondGrid} order={2}>
        {footerItems.map((item) => (
          <Link
            to={item.link}
            style={{ color: "white", textDecoration: "none" }}
          >
            {item.text}
          </Link>
        ))}
      </Grid>
      <Grid xs={12} md={4} sx={thirdGrid} order={{ xs: 1, md: 3 }}>
        <OutlinedInput
          fullWidth
          size="small"
          placeholder="Email Address"
          sx={{ color: "white", borderRadius: "50px", padding: "2px" }}
        />
        <Button
          variant="contained"
          fullWidth
          disableElevation
          sx={{
            bgcolor: "white",
            color: "#1955AA",
            // padding: "8px",
            borderRadius: "50px",
            padding: "8px",
            "&:focus": {
              //   display: "none",
              backgroundColor: "#FF9820",
              color: "white",
            },
            "&:hover": {
              //   display: "none",
              backgroundColor: "#FF9820",
              color: "white",
            },
          }}
          onClick={() => {
            navigate("/signup");
          }}
        >
          Sign Up
        </Button>
      </Grid>
    </Grid>
  );
}

export default Footer;
